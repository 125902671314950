import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Col } from 'react-grid-system';
import { shopData } from '../data/';
import { ArrowBack, CartAdd, SearchIcon } from '../icons';
import { hexToRgb } from '../functions';
import { 
  Badge, 
  ButtonCustom, 
  ContainerCustom, 
  DescriptionUnderwearMan, 
  DescriptionUnderwearThongWoman, 
  DescriptionUnderwearBrazilianWoman, 
  DescriptionTShirtChild, 
  DescriptionSweatsuitChild, 
  DescriptionTShirtWoman, 
  DescriptionTShirtMan,
  DescriptionTShirtMan100PercentageCotton,
  DescriptionTShirtMan100PercentageCotton2,
  DescriptionSweatshirtWoman, 
  DescriptionSweatshirtManHalfSlim, 
  DescriptionSweatshirtMan, 
  DescriptionSweatshirtMan2, 
  DescriptionSweatsuitWomanDoubleface, 
  DescriptionSweatsuitManDoubleface, 
  DescriptionTopWoman, 
  DescriptionPajamasWoman,
  DescriptionPajamasChildren,
  Modal, 
  TextCustom, 
  Toggler,
} from '../components';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../redux-slices/cartSlice';
import { triggerSnackbar } from '../redux-slices/snackbarSlice';

const ActionsWrapperStyled = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  -webkit-box-shadow: 0px -5px 5px -5px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .75)`};
  -moz-box-shadow: 0px -5px 5px -5px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .75)`};
  box-shadow: 0px -5px 5px -5px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .75)`};

  & > a > button:hover, & > button:hover {
    transition: .3s;
    transform: scale(1.02);
  }

  & > a > button:hover {
    transition: .3s;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(${props => hexToRgb(props.theme.colors.darkPrimary)}, .5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(${props => hexToRgb(props.theme.colors.darkPrimary)}, .5);
    box-shadow: 0px 0px 5px 0px rgba(${props => hexToRgb(props.theme.colors.darkPrimary)}, .5);
  }

  & > button:hover {
    transition: .3s;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(${props => hexToRgb(props.theme.colors.primaryColor)}, .5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(${props => hexToRgb(props.theme.colors.primaryColor)}, .5);
    box-shadow: 0px 0px 5px 0px rgba(${props => hexToRgb(props.theme.colors.primaryColor)}, .5);
  }

  @media (max-width: 993px) {
    button {
      padding: 10px 20px;
      font-size: .85rem;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;

    & > a, & > button, & > a > button {
      width: 100%;
    }

    & > a {
      margin-bottom: 15px;
    }
  }
`;

const InputTextStyled = styled.input`
  width: 80px;
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 1rem;

  &:focus {
    border: 1px solid ${props => props.theme.colors.primaryColor};
  }
`;

const DescriptionWrapperStyled = styled.div`
  & > article:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;

const DescriptionArticleStyled = styled.article`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;

  h1, h2, h3 {
    margin-bottom: 0;
  }

  @media (max-width: 450px) {
    h3 > span {
      display: inline-block;
      width: 100%;
    }

    h3 > span:first-of-type {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
    }
  }
`;

const SizesArticleStyle = styled(DescriptionArticleStyled)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-right: 5px;
  }

  button {
    cursor: pointer;
    margin-right: 3px;

    &:hover, &.active {
      transition: .3s;
      background-color: ${props => props.theme.colors.primaryColor};
      color: ${props => props.theme.colors.darkPrimary};
    }
  }

  p {
    width: 100%;
  }

  @media (max-width: 450px) {
    h2 {
      width: 100%;
      margin-bottom: 15px;
    }
  }
`;

const CurrentImageStyled = styled.div`
  -webkit-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
  -moz-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
  box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
  background-image: ${props => `url(${props.$bgImage})` || 'unset'};
  background-size: cover;
  background-position: center center;
  aspect-ratio : 1 / 1;

  &:hover {
    transition: .3s;
    -webkit-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
		-moz-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
		box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
  }

  @media (max-width: 768px) {
    & ~ div {
      margin-bottom: 15px;
    }
  }
`;

const ImageListItemStyled = styled.div`
  cursor: pointer;
  width: calc(20% - 10px);
  aspect-ratio: 1 / 1;
  margin: 0 0 5px 0;
  background-image: ${props => `url(${props.$bgImage})` || 'unset'};
  background-size: cover;
  background-position: center center;
  -webkit-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
  -moz-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
  box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};

  &.active {
    -webkit-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
		-moz-box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
		box-shadow: 0px 0px 2px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
    border: 1px solid ${props => props.theme.colors.primaryColor};
  }
`;

const ImageListStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const ViewImageTrigger = styled.span`
  position: absolute;
  top: 5px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};

  &:hover {
    transition: .3s;
    background-color: ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .8)`};
    -webkit-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
		-moz-box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
		box-shadow: 0px 0px 5px 0px ${props => `rgba(${hexToRgb(props.theme.colors.primaryColor)}, .5)`};
  }
`;

const ViewImageActiveImage = styled.img`
  max-width: 90vw;
  max-height: 90vh;
`;

const ShopItem = () => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useSelector(state => state.settings.theme);
  const selectedLanguage = useSelector(state => state.settings.localization.selectedLanguage);
  const { lightPrimary, darkPrimary, primaryColor } = theme.colors;
  const [pickedItem, setPickedItem] = useState();
  const [sizeError, setSizeError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [quantityErrorContent, setQuantityErrorContent] = useState(false);
  const [product, setProduct] = useState();
  const [currentLeadingImage, setCurrentLeadingImage] = useState();
  const [itemQuantity, setItemQuantity] = useState(0);
  const [itemSize, setItemSize] = useState('');
  const [isSold, setIsSold] = useState(false);

  useEffect(() => {
    const shopDataItems = shopData(t);
    if (productId) {
      let productItem = shopDataItems.find(item => item.id === productId);
      if (productItem !== undefined) {
        setProduct(productItem);
        setCurrentLeadingImage(productItem?.leadingImage);
        productItem['selectedQuantity'] = 0;
        productItem['selectedSize'] = '';
        setPickedItem(productItem);

        if (productItem.mark === 'prodato' || productItem.mark === 'sold') {
          setIsSold(true);
        }
      } else {
        navigate('/not-found');
      }
    }

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		
		return () => window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [t, productId, navigate]);

  // console.log(product);

  const pickItemQuantity = (val) => {
    let updatedPickedItem = pickedItem;
    updatedPickedItem['selectedQuantity'] = val;
    setPickedItem(updatedPickedItem);
    setItemQuantity(parseInt(val));

    if (val[0] === '0') {
      const quantityValue = val.substring(1);
      setItemQuantity(parseInt(quantityValue));
    }

    if (val === '') {
      setItemQuantity(0);
    }
  }

  const pickItemSize = (val) => {
    let updatedPickedItem = pickedItem;
    updatedPickedItem['selectedSize'] = val;
    setPickedItem(updatedPickedItem);
    setItemSize(val);
  }

  const addProductToCart = () => {
    if (parseInt(itemQuantity) < 0 || parseInt(itemQuantity) > 10 || parseInt(itemQuantity) === 0 || isNaN(parseInt(itemQuantity))) {
      if (parseInt(itemQuantity) < 0) {
        setQuantityError(true);
        setQuantityErrorContent("shop-item-quantity-error-min");
      }

      if (parseInt(itemQuantity) > 10) {
        setQuantityError(true);
        setQuantityErrorContent("shop-item-quantity-error-max");
      }

      if (parseInt(itemQuantity) === '') {
        setQuantityError(true);
        setQuantityErrorContent("shop-item-quantity-error-number");
      }

      if (parseInt(itemQuantity) === 0) {
        setQuantityError(true);
        setQuantityErrorContent("shop-item-quantity-error");
      }
    } else {
      setQuantityError(false);
      setQuantityErrorContent(null);
    }

    if (itemSize === '') {
      setSizeError(true);
    } else {
      setSizeError(false);
    }

    if (itemQuantity > 0 && itemQuantity < 11 && itemQuantity !== '' && itemQuantity !== 0 && itemSize !== '') {
      const snackbarInfo = {
        show: true,
        icon: 'productAdded',
        message: t("cart-added-notification"),
        bgColor: primaryColor,
        color: darkPrimary,
        seconds: 5,
      }
      dispatch(addToCart(pickedItem));
      dispatch(triggerSnackbar(snackbarInfo));
      navigate('/shop');
    }
  }

  return (
    <>
      <ContainerCustom 
        fluid={true}
        align='center'
        justify='center'
        bgImage='/images/shop-cover.jpg' 
        bgImageSize='cover' 
        bgImagePosition='center center' 
        minHeight='200px'
        bgRgbaColor={hexToRgb(darkPrimary)} 
        bgRgbaOpacity={.9}
      >
        <Col className='text-light text-center'>
          <TextCustom as='h1' size='2.5rem' margin='0 0 10px 0'>
            <TextCustom as='span' color={primaryColor}>{product?.title}</TextCustom>
          </TextCustom>
        </Col>
      </ContainerCustom>

      <ContainerCustom padding='50px 0px' justify='between' bgColor={lightPrimary}>
        <Col md={5} lg={4} xl={3}>
          <CurrentImageStyled $bgImage={currentLeadingImage}>
            <Modal
              trigger={
                <ViewImageTrigger>
                  <SearchIcon fill={lightPrimary} width={20} height={20} />
                </ViewImageTrigger>
              }
            >
              <ViewImageActiveImage src={currentLeadingImage} />
            </Modal>
          </CurrentImageStyled>

          <ImageListStyled>
            {product?.images.length && product?.images.map((item) => <ImageListItemStyled 
              key={`shop-item-image-${item.id}`} 
              $bgImage={item.src} 
              className={item === currentLeadingImage ? 'active' : ''} 
              onClick={() => setCurrentLeadingImage(item.src)}
            />)}
          </ImageListStyled>
        </Col>

        <Col md={7} lg={8} xl={9}>
          <DescriptionWrapperStyled>
            <DescriptionArticleStyled>
              <TextCustom as='h3' size='1rem' margin='0 0 10px 0'>
                <span>
                  {t("shop-item-type")} <Badge>{product?.type ?? ''}</Badge>
                </span>
                <span>
                  {t("shop-item-collection")} <Badge>{product?.collection ?? ''}</Badge>
                </span>
              </TextCustom>
            </DescriptionArticleStyled>

            <DescriptionArticleStyled>
              <Toggler title={t("shop-item-description")}>
                {product?.description === 'underwear-man' ? <DescriptionUnderwearMan /> : null}
                {product?.description === 'underwear-thong-woman' ? <DescriptionUnderwearThongWoman /> : null}
                {product?.description === 'underwear-brazilian-woman' ? <DescriptionUnderwearBrazilianWoman /> : null}
                {product?.description === 't-shirt-child' ? <DescriptionTShirtChild /> : null}
                {product?.description === 'sweatsuit-child' ? <DescriptionSweatsuitChild /> : null}
                {product?.description === 't-shirt-woman' ? <DescriptionTShirtWoman /> : null}
                {product?.description === 't-shirt-man' ? <DescriptionTShirtMan /> : null}
                {product?.description === 't-shirt-man-cotton-100-percentage' ? <DescriptionTShirtMan100PercentageCotton /> : null}
                {product?.description === 't-shirt-man-cotton-100-percentage-2' ? <DescriptionTShirtMan100PercentageCotton2 /> : null}
                {product?.description === 'sweatshirt-woman' ? <DescriptionSweatshirtWoman /> : null}
                {product?.description === 'sweatshirt-man-half-slim' ? <DescriptionSweatshirtManHalfSlim /> : null}
                {product?.description === 'sweatshirt-man' && <DescriptionSweatshirtMan />}
                {product?.description === 'sweatshirt-man2' && <DescriptionSweatshirtMan2 />}
                {product?.description === 'sweatsuit-woman-double-face' ? <DescriptionSweatsuitWomanDoubleface /> : null}
                {product?.description === 'sweatsuit-man-double-face' ? <DescriptionSweatsuitManDoubleface /> : null}
                {product?.description === 'top-woman' ? <DescriptionTopWoman /> : null}
                {product?.description === 'pajamas-woman' && <DescriptionPajamasWoman />}
                {product?.description === 'pajamas-children' && <DescriptionPajamasChildren />}
              </Toggler>
            </DescriptionArticleStyled>

            <SizesArticleStyle>
              <TextCustom as='h2' size='1rem' margin='0 0 10px 0'>
                *{t("shop-item-size")} 
              </TextCustom>
              <>
                {product?.size.length && product?.size.map((item, index) => <ButtonCustom 
                  key={`shop-item-size-${index}`}
                  className={itemSize === item ? 'active' : ''}
                  as='button'
                  size='10px'
                  padding='5px 10px'
                  onClick={() => pickItemSize(item)}
                >
                  {item.toUpperCase()}
                </ButtonCustom>)}
              </>
              {sizeError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{t("shop-item-size-error")}</TextCustom>}
            </SizesArticleStyle>

            <DescriptionArticleStyled>
              <TextCustom as='h2' size='1rem' margin='0 0 10px 0'>*{t("shop-item-quantity")}</TextCustom>
              <InputTextStyled 
                type='number' 
                min={0}
                max={10}
                disabled={!isSold ? product?.availableQuantity && product?.availableQuantity > 0 ? false : true : true}
                value={itemQuantity} 
                onChange={(e) => pickItemQuantity(e.target.value)}
              />
              {quantityError && <TextCustom as='p' size='.7rem' margin='5px 0 0 0' color='#cc0000'>{quantityErrorContent && t(quantityErrorContent)}</TextCustom>}
            </DescriptionArticleStyled>

            <DescriptionArticleStyled>
              <TextCustom as='h2' size='1.1rem' margin='0 0 10px 0'>{t("shop-item-price")}</TextCustom>
              <TextCustom as='h2' size='2rem' margin='0 0 10px 0'>
                {selectedLanguage === 'en' ? 
                  `€ ${product?.priceEURO}` ?? '€ 0' : 
                  `${product?.priceRSD} RSD` ?? '0 RSD'
                } <TextCustom as='span' color='#cc0000' textTransform='uppercase'>{isSold ? t('mark-sold') : ''}</TextCustom>
              </TextCustom>
            </DescriptionArticleStyled>

          </DescriptionWrapperStyled>

          <ActionsWrapperStyled>
            <Link to='/shop'>
              <ButtonCustom as='button' textTransform='uppercase'>
                <ArrowBack width={13} height={13} fill={lightPrimary} /> {t("shop-item-back-button")}
              </ButtonCustom>
            </Link>

            <ButtonCustom as='button' disabled={isSold} backgroundColor={primaryColor} color={darkPrimary} textTransform='uppercase' onClick={() => addProductToCart(t)}>
              <CartAdd width={15} height={15} fill={darkPrimary} /> {t("shop-item-add-button")}
            </ButtonCustom>
          </ActionsWrapperStyled>
        </Col>
      </ContainerCustom>
    </>
  );
};

export default ShopItem;
