import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TableSize = styled.table`
  width: 100%;
  border-collapse: collapse;

  &, th, td {
    border: 1px solid ${props => props.theme.colors.darkPrimary};
  }

  tr td:first-of-type {
    width: 95px;
    background-color: ${props => props.theme.colors.darkPrimary};
    color: ${props => props.theme.colors.lightPrimary};
    border-color: ${props => props.theme.colors.lightPrimary};
  }

  td {
    padding: 3px 5px;
  }

  tr:hover {
    td:first-of-type {
      transition: .3s;
      color: ${props => props.theme.colors.primaryColor};
    }
  }
`;

const DescriptionPajamasWoman = () => {
  const { t } = useTranslation();

  return (
    <>
      <p>{t("description-pajamas-woman")}</p>
      <p>{t("description-material-global")}</p>
      <ul>
        <li>{t("description-cotton")} 42%</li>
        <li>Modal 51%</li>
        <li>{t("description-elastine")} 7%</li>
      </ul>
      <TableSize>
        <tr>
          <td className='text-center' colspan='6'>{t("description-pajamas")}</td>
        </tr>
        <tr>
          <td>{t("description-size")}</td>
          <td>XS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-pajamas-top-part")}</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-pajamas-top-part-height")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>57</td>
          <td>59</td>
          <td>61</td>
          <td>63</td>
          <td>65</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-pajamas-top-part-width")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>51</td>
          <td>53</td>
          <td>55</td>
          <td>57</td>
          <td>59</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-pajamas-bottom-part")}</td>
        </tr>
        <tr>
          <td>{t("description-size")}</td>
          <td>XS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-tracksuit-height")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>89</td>
          <td>91</td>
          <td>93</td>
          <td>95</td>
          <td>97</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-tracksuit-width")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>49</td>
          <td>51</td>
          <td>53</td>
          <td>55</td>
          <td>57</td>
        </tr>
      </TableSize>
    </>
  );
};

export default DescriptionPajamasWoman;
