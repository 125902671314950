import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TableSize = styled.table`
  width: 100%;
  border-collapse: collapse;

  &, th, td {
    border: 1px solid ${props => props.theme.colors.darkPrimary};
  }

  tr td:first-of-type {
    width: 95px;
    background-color: ${props => props.theme.colors.darkPrimary};
    color: ${props => props.theme.colors.lightPrimary};
    border-color: ${props => props.theme.colors.lightPrimary};
  }

  td {
    padding: 3px 5px;
  }

  tr:hover {
    td:first-of-type {
      transition: .3s;
      color: ${props => props.theme.colors.primaryColor};
    }
  }
`;

const DescriptionPajamasChildren = () => {
  const { t } = useTranslation();

  return (
    <>
      <p>{t("description-pajamas-children")}</p>
      <p>{t("description-material-global")}</p>
      <ul>
        <li>{t("description-cotton")} 42%</li>
        <li>Modal 51%</li>
        <li>{t("description-elastine")} 7%</li>
      </ul>
      <TableSize>
        <tr>
          <td className='text-center' colspan='6'>{t("description-pajamas")}</td>
        </tr>
        <tr>
          <td>{t("description-size")}</td>
          <td>2</td>
          <td>4</td>
          <td>5</td>
          <td>8</td>
          <td>10</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-pajamas-top-part")}</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-pajamas-top-part-height")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>37</td>
          <td>42</td>
          <td>48</td>
          <td>54</td>
          <td>60</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-pajamas-top-part-width")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>29</td>
          <td>37</td>
          <td>39</td>
          <td>42</td>
          <td>45</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-pajamas-bottom-part")}</td>
        </tr>
        <tr>
          <td>{t("description-size")}</td>
          <td>2</td>
          <td>4</td>
          <td>5</td>
          <td>8</td>
          <td>10</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-tracksuit-height")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>47</td>
          <td>53</td>
          <td>64</td>
          <td>68</td>
          <td>72</td>
        </tr>
        <tr>
          <td className='text-center' colspan='6'>{t("description-tracksuit-width")}</td>
        </tr>
        <tr>
          <td>cm</td>
          <td>31</td>
          <td>34</td>
          <td>38</td>
          <td>42</td>
          <td>46</td>
        </tr>
      </TableSize>
    </>
  );
};

export default DescriptionPajamasChildren;
