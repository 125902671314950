export const en = {
	// global
	"page-about-us": "About us",
	"page-collections": "Collections",
	"page-shop": "Shop",
	"page-cart": "Cart",
	"page-gallery": "Gallery",
	"page-contact": "Contact",
	"loading": "loading...",
	// header
	"page-description": "Go to home for basic info.",
	"page-about-us-description": "Find out more about us, who we are and what we offer.",
	"page-collections-description": "Discover our collections.",
	"page-shop-description": "In our shop you can purchase our products, filter them, and find out everything of them.",
	"page-cart-description": "Check cart and proceed to order.",
	"page-gallery-description": "Be free to fall in love with our products :).",
	"page-contact-description": "Don't hesitate to contact us, we are here to answer to any question.",
	// footer
	"footer-navigation-title": "Navigation",
	"footer-contact-us-title": "Contact us",
	"footer-follow-us-title": "Follow us",
	"copyright": "All rights reserved",
	// home page
	"home-welcome": "It's time to love your clothes!",

	"home-about-title": "What is",
	"home-about-paragraph-1": "Valore Intimo represents a company that mainly deals with the production of a unique brand of underwear. In addition to the production and sale of underwear, Valore Intimo also offers an assortment of children's clothing, as well as tracksuits and sweatshirts for adults.",
	"home-about-paragraph-2": "In addition to the current designs, Valore Intimo strives to constantly provide new design of underwear, as well as to create a unique design of clothing for all ages. Thanks to the professional team, you can find quality products at very good prices.",
	"home-about-paragraph-3": "Thanks to the unique brand, Valore Intimo is increasingly breaking into the world market with a large increase in sales of its products. On this site you can find all the information about us, as well as about the services and products we offer.",

	"home-top-product-title": "Our",
	"home-top-product-title-span": "top products",

	"home-collections-title-span": "collections",
	"home-collections-paragraph-1": "During many years of work, our company managed to expand production to cover all target groups and creating collections - men, women and children. Our assortment grows over time, and every quarter you can expect new designs in all collections. You can make your loved ones happy with our products, to whom you can order a gift through our store.",
	"home-collection-item-man": "man",
	"home-collection-item-woman": "woman",
	"home-collection-item-child": "child",
	"home-collection-item-t-shirt": "t-shirt",
	"home-collection-item-sweatsuit": "sweatsuit",
	"home-collection-item-sweatshirt": "sweatshirt",
	"home-collection-item-underwear": "underwear",
	"home-collection-item-button": "explore now",

	"home-best-seller-title": "Our",
	"home-best-seller-title-span": "best seller",
	"home-best-seller-material-title": "Material",
	"home-best-seller-material-description": "For the production of our products, only premium materials are used, woven from the finest cotton, which enables unhindered activity during the day or night.",
	"home-best-seller-quality-title": "Quality",
	"home-best-seller-quality-description": "In order to ensure the maximum durability of our products, each of our products is adequately processed and tested.",
	"home-best-seller-adaptability-title": "Adaptability",
	"home-best-seller-adaptability-description": "In our assortment you can find products for all seasons, so that you feel comfortable and move freely during winter, rainy and warm sunny days, as well as during days spent in the comfort of your home.",
	"home-best-seller-idea-title": "Idea",
	"home-best-seller-idea-description": "The vision is to offer something completely new in the sea of the same products, a new look at the world of intimate underwear design and at the same time special and adapted to every age.",
	"home-best-seller-button": "buy now",
	// about us page
	"about-us-welcome-title": "About",
	"about-us-welcome-description": "Explore more about our company.",

	"about-us-basic-title": "What is",
	"about-us-basic-paragraph-1": "Valore Intimo is a brand driven by the idea of offering customers a \"different\" shopping experience. The company is primarily engaged in the production of underwear's. In addition to underwear's, the offer includes a wide range of clothing for children and adults (t-shirts, sweatshirts, tracksuits, tops, socks, etc.).",
	"about-us-basic-paragraph-2": "The vision is to offer something completely new in the sea of the same products, a new look at the world of intimate underwear design and at the same time special and adapted to every age.",
	"about-us-basic-paragraph-3": "Each of our products is carefully designed, assembled and tested, from thread, material, through printing to packaging. For the production of our products, only premium materials are used, woven from the finest cotton, which enables unhindered activity during the day or night.",
	"about-us-basic-paragraph-4": "With one piece of our product that comes into your hands, comes a lot of effort, energy and love, invested in order to convey to you every detail of our idea.",

	"about-us-timeline-title": "Our",
	"about-us-timeline-title-span": "timeline",
	"about-us-timeline-item-1-title": "Final brand",
	"about-us-timeline-item-1-description": "In 2022, we paid attention to clothing combinations intended for daily use, so that all your activities could go smoothly thanks to the comfort of our products. In addition to the expansion of the program, at the same time work was done on the improvement of already existing products.",
	"about-us-timeline-item-2-title": "Market expansion",
	"about-us-timeline-item-2-date": "September 2020",
	"about-us-timeline-item-2-description": "After 2 years since the founding of the brand, we completed the program with various designs and expanded it to men's, women's and children's t-shirts, as well as children's vests.",
	"about-us-timeline-item-3-title": "Our first product",
	"about-us-timeline-item-3-date": "July 2018",
	"about-us-timeline-item-3-description": "After several months of market research, we narrowed down the choice to the textile production of men's and women's intimate underwear, made of the finest cotton, while at the same time offering a new perspective on the world of intimate underwear design.",
	"about-us-timeline-item-4-title": "When idea was born?",
	"about-us-timeline-item-4-date": "November 2017",
	"about-us-timeline-item-4-description": "Valore Intimo is a brand driven by the idea of offering customers a \"different\" shopping experience. The first idea was to start something by which we would be recognizable and different from others. What can we offer customers in order to complete a day full of activities?",
	// collections page
	"collections-welcome-title-man": "Men's collection",
	"collections-welcome-title-woman": "Women's collection",
	"collections-welcome-title-child": "Children's collection",
	"collections-welcome-button": "explore now",

	"collections-man-title-span": "Men's",
	"collections-man-title": "collection",
	"collections-man-paragraph-1": "In our assortment you can find men's clothing with an interesting design, made of the finest cotton. We pay adequate attention to each manufactured piece, in order to satisfy every customer with its characteristics.",
	"collections-man-paragraph-2": "The men's collection is made up of interesting designs, which complete their look and make them stand out from the standard everyday offer. In addition to intimate clothing, we also offer a range of t-shirts, produced from quality-tested cotton.",
	"collections-man-paragraph-3": "We also offer sweatshirts, as well as tracksuit sets that will complete your look in changeable and cold weather conditions.",
	"collections-woman-title-span": "Women's",
	"collections-woman-title": "collection",
	"collections-woman-paragraph-1": "Adequate attention is paid to women's intimate clothing, in order to bring the smallest details to perfection and thus enable maximum comfort during everyday activities.",
	"collections-woman-paragraph-2": "We offer casual and slim-fit t-shirt models, as well as double-face sets of tracksuits that enable unhindered activity and provide ease of movement.",
	"collections-woman-paragraph-3": "Also, you can complete your styling with oversize sweatshirts in a relaxed day version, where you will overcome any challenge with ease.",
	"collections-child-title-span": "Children's",
	"collections-child-title": "collection",
	"collections-child-paragraph-1": "The children's program offers variety, because simplicity is not an option in order to keep children intrigued.",
	"collections-child-paragraph-2": "Continuous activity during the day or night must not be limited by inadequate products that prevent their freedom of movement.",
	"collections-child-paragraph-3": "Everything for children is just the beginning of the offer, which makes them smile at the very sight of interesting prints, which even the elderly cannot resist. In order to show our little ones what comfort and unhindered activity are, we have completed our program with t-shirts of various designs, as well as tracksuit sets that they simply don't want to part with.",
	// shop page
	"shop-welcome-title-span": "shop",
	"shop-welcome-description": "Discover and buy various products in our shop.",

	"shop-filters": "Filters",
	"shop-products": "Products",
	"shop-filters-title": "Title",
	"shop-filters-title-placeholder": "Search by title",
	"shop-filters-size": "Size",
	"shop-filters-type": "Type",
	"shop-filters-type-underwear": "underwear",
	"shop-filters-type-t-shirt": "t-shirt",
	"shop-filters-type-sweatshirt": "sweatshirt",
	"shop-filters-type-sweatsuit": "sweatsuit",
	"shop-filters-type-top": "top",
	"shop-filters-type-pajamas": "pajamas",
	"shop-filters-collection": "Collection",
	"shop-filters-collection-man": "man",
	"shop-filters-collection-woman": "woman",
	"shop-filters-collection-child": "child",
	"shop-filters-button": "reset filters",

	"shop-no-products": "There are no products to display.",

	"shop-product-collection-man": "man",
	"shop-product-collection-woman": "woman",
	"shop-product-collection-child": "child",

	"shop-product-type-underwear": "underwear",
	"shop-product-type-t-shirt": "t-shirt",
	"shop-product-type-sweatshirt": "sweatshirt",
	"shop-product-type-sweatsuit": "sweatsuit",
	"shop-product-type-top": "top",
	"shop-product-type-pajamas": "pajamas",
	// shop data
	"mark-new": "new",
	"mark-sold": "sold",
	"underwear-mole": "Underwear Mole",
	"underwear-schnauzer": "Underwear Schnauzer",
	"underwear-gray": "Underwear Gray",
	"underwear-rooster": "Underwear Rooster",
	"underwear-yellow": "Underwear Yellow",
	"underwear-snails": "Underwear Snails",
	"underwear-horses": "Underwear Horses",
	"underwear-birds": "Underwear Birds",
	"underwear-red-frogs": "Underwear Frogs",
	"underwear-mongooses": "Underwear Mongooses",
	"underwear-giraffes": "Underwear Giraffes",
	"underwear-mosquitoes": "Underwear Mosquitoes",
	"underwear-boars": "Underwear Pigs",
	"underwear-hedgehogs": "Underwear Hedgehogs",
	"underwear-turtles": "Underwear Turtles",
	"underwear-half-boxers-dark-blue": "Underwear Boxers Dark Blue",
	"underwear-half-boxers-hen": "Underwear Half Boxers Hen",
	"underwear-half-boxers-tufted": "Underwear Half Boxers Tufted",
	"underwear-half-boxers-flamingos": "Underwear Half Boxers Flamingos",
	"underwear-half-boxers-amarant": "Underwear Half Boxers Amarant",
	"underwear-half-boxers-maroon": "Underwear Half Boxers Maroon",
	"underwear-half-boxers-olive-green": "Half Boxers Olive Green",
	"underwear-half-boxers-dark-brown": "Half Boxers Dark Brown",
	"underwear-half-boxers-scooter": "Half Boxers Scooter",
	"underwear-triangles": "Underwear Triangles",
	"underwear-boats": "Underwear Boats",
	"underwear-blue": "Underwear Blue",
	"underwear-amarant": "Underwear Amarant",
	"underwear-maroon": "Underwear Maroon",
	"underwear-soft-pink": "Underwear Soft Pink",
	"underwear-orange": "Underwear Orange",
	"underwear-green": "Underwear Green",
	"underwear-puppies": "Underwear Puppies",
	"underwear-tent": "Underwear Tent",
	"underwear-mustache": "Underwear Mustache",
	"underwear-black": "Underwear Black",
	"underwear-tufted": "Underwear Tufted",
	"underwear-apricot": "Underwear Apricot",
	"underwear-olive-green": "Underwear Olive",
	"underwear-fat-man": "Underwear Fat Man",
	"underwear-guitars": "Underwear Guitars",
	"underwear-ants": "Underwear Ants",
	"thongs-purple": "Thongs Purple",
	"thongs-orange": "Thongs Orange",
	"thongs-orange-light": "Thongs Orange Light",
	"thongs-dark-green": "Thongs Dark Green",
	"thongs-birds": "Thongs Birds",
	"thongs-green": "Thongs Green",
	"thongs-pink": "Thongs Pink",
	"thongs-brown": "Thongs Brown",
	"thongs-yellow": "Thongs Yellow",
	"thongs-kerosene": "Thongs Kerosene",
	"thongs-dark-blue": "Thongs Dark Blue",
	"thongs-hen": "Thongs Hen",
	"thongs-amarant": "Thongs Amarant",
	"thongs-maroon": "Thongs Maroon",
	"thongs-soft-pink": "Thongs Soft Pink",
	"thongs-mint": "Thongs Mint",
	"thongs-chickens": "Thongs Chickens",
	"thongs-black-minimalistic": "Thongs Black Minimalistic",
	"thongs-tufted": "Thongs Tufted",
	"thongs-flamingo": "Thongs Flamingo",
	"thongs-pink-2": "Thongs Nude Pink",
	"thongs-scooter": "Thongs Scooter",
	"thongs-dark-brown": "Thongs Dark Brown",
  "thongs-olive-green": "Thongs Olive Green",
	"brazilian-amarant": "Brazilian Amarant",
	"brazilian-maroon": "Brazilian Maroon",
	"brazilian-soft-pink": "Brazilian Soft Pink",
	"brazilian-purple": "Brazilian Panties Purple",
	"brazilian-orange": "Brazilian Panties Orange",
	"brazilian-brown": "Brazilian Panties Brown",
	"brazilian-light-green": "Brazilian Panties Light Green",
	"brazilian-dark-green": "Brazilian Panties Dark Green",
	"brazilian-birds": "Brazilian Panties Birds",
	"brazilian-yellow": "Brazilian Panties Yellow",
	"brazilian-panties-kerosene": "Brazilian Panties Kerosene",
	"brazilian-panties-dark-blue": "Brazilian Panties Dark Blue",
	"brazilian-panties-hen": "Brazilian Panties Hen",
	"brazilian-tufted": "Brazilian Tufted",
	"brazilian-flamingo": "Brazilian Flamingo",
	"brazilian-mint": "Brazilian Mint",
	"brazilian-black-minimalistic": "Brazilian Black Minimalistic",
	"brazilian-panties-olive-green": "Brazilian Panties Olive Green",
	"brazilian-panties-pink": "Brazilian Panties Nude Pink",
	"brazilian-panties-dark-brown": "Brazilian Panties Dark Brown",
	"brazilian-panties-scooter": "Brazilian Panties Scooter",
	"t-shirt-schnauzer": "T-Shirt Schnauzer",
	"t-shirt-unisex-dog": "T-Shirt Unisex Dog",
	"t-shirt-unisex-giraffe": "T-Shirt Unisex Giraffe",
	"t-shirt-unisex-bear": "T-Shirt Unisex Bear",
	"t-shirt-unisex-bird": "T-Shirt Unisex Bird",
	"t-shirt-slim-fit-black": "T-Shirt Slim-Fit Black",
	"t-shirt-chamois": "T-Shirt Chamois",
	"t-shirt-brown": "T-Shirt Brown",
	"t-shirt-light-blue": "T-Shirt Light Blue",
	"t-shirt-moja-slavice": "T-Shirt 'Moja Slavice'",
	"t-shirt-black": "T-Shirt Black",
	"t-shirt-dark-grey": "T-Shirt Dark Grey",
	"t-shirt-kerosene": "T-Shirt Kerosene",
	"sweatsuit-unisex-light-green": "Sweatsuit Unisex Light Green",
	"sweatsuit-unisex-dino": "Sweatsuit Unisex Dino",
	"sweatsuit-unisex-orange": "Sweatsuit Unisex Orange",
	"sweatshirt-green-oversize": "Sweatshirt Green Oversize",
	"sweatshirt-black-half-slim": "Sweatshirt Black Half-Slim",
	"sweatshirt-sloth-half-slim": "Sweatshirt Sloth Half-Slim",
	"sweatshirt-orange": "Sweatshirt Orange",
	"sweatshirt-black": "Sweatshirt Black",
	"sweatshirt-brown": "Sweatshirt Brown",
	"sweatshirt-apricot": "Sweatshirt Apricot",
	"sweatsuit-double-face-orange": "Sweatsuit Double-Face Orange",
	"sweatsuit-double-face-light": "Sweatsuit Double-Face Light",
	"sweatsuit-double-face-black": "Sweatsuit Double-Face Black",
	"top-hen": "Top Hen",
	"top-olive-green": "Top Olive Green",
	"top-dark-brown": "Top Dark Brown",
	"pajamas-pearl-dreamer": "Pajamas Pearl Dreamer",
	"pajamas-children-pearl-dreamer": "Pajamas Children Pearl Dreamer",
	// shop data description
	"description-sweatshirt-man": "Men's sweatshirts are a classic model that suits all age groups. VALORE logo is nicely printed on model.",
	"description-sweatshirt-man-half-slim": "Men's sweatshirts are a classic model that suits all age groups. VALORE logo is nicely printed on model.",
	"description-t-shirt-woman": "Women's t-shirts are made of the finest cotton that adapts to your body and allows for unhindered movement. We offer a classic (casual) model, as well as a slim fit model.",
	"description-t-shirt-man": "Men's t-shirts are made of the finest cotton that adapts to your body and allows for unhindered movement.",
	"description-sweatshirt-woman": "Women's sweatshirts are made of the finest cotton that adapts to your body and allows for unhindered movement.",
	"description-sweatsuit-child": "Children's sweatsuits are a classic model that suits all age groups. With funny illustrations, your child will be interested and happy to wear it.",
	"description-sweatsuit-man-double-face": "Mans's sweatsuits are a classic model that suits all age groups. VALORE logo is nicely printed on model.",
	"description-sweatsuit-woman-double-face": "Womens's sweatsuits are a classic model that suits all age groups. VALORE logo is nicely printed on model.",
	"description-t-shirt-child": "Childrens t-shirts are a classic model that suits all age groups. With funny illustrations, your child will be interested and happy to wear it.",
	"description-underwear-brazilian": "Womens's brazilian panties are a classic model that suits all age groups. Belt with stamped VALORE is found on every model which is reinforced central part.",
	"description-underwear-man": "Men's boxers are a classic model that suits all age groups. Belt with stamped VALORE is found on every model which is reinforced central part.",
	"description-underwear-woman": "Womens's thongs are a classic model that suits all age groups. Belt with stamped VALORE is found on every model which is reinforced central part.",
	"description-material-global": "Machine wash up to 60 degrees. The use of bleach is prohibited. The size of label is silk, in order to give additional comfort. Material:",
	"description-top": "Womens's top are a classic model that suits all age groups. Belt with stamped VALORE is found on every model. Does not contain an insert.",
	"description-pajamas-woman": "Women's pajamas are made of the finest material that adapts to your body and allows for unhindered movement.",
	"description-pajamas-children": "Children's pajamas are made of the finest material that adapts to your body and allows for unhindered movement.",
	"description-cotton": "Cotton",
	"description-elastine": "Elastine",
	"description-size": "Size",
	"description-inches": "inches",
	"description-type": "type",
	"description-sweatshirt-height": "The height of the sweatshirt",
	"description-sweatshirt-width": "The width of the sweatshirt",
	"description-sweatshirt": "SWEATSHIRT",
	"description-pajamas-top-part": "Top part",
	"description-pajamas-top-part-height": "The height of the pajamas top part",
	"description-pajamas-top-part-width": "The width of the pajamas top part",
	"description-pajamas-bottom-part": "Bottom part",
	"description-pajamas-bottom-part-height": "The height of the pajamas bottom part",
	"description-pajamas-bottom-part-width": "The width of the pajamas bottom part",
	"description-pajamas": "PAJAMAS",
	"description-tracksuit": "TRACKSUIT",
	"description-tracksuit-height": "The height of the tracksuit",
	"description-tracksuit-width": "The width of the tracksuit",
	"description-t-shirt-height": "The height of the t-shirt",
	"description-t-shirt-width": "The width of the t-shirt",
	"description-waist": "Waist",
	"chest-circumference": "Chest circumference",
	"description-hips": "Hips",
	"description-waist-size": "Waist size",
	"description-thigh-size": "Thigh size",
	// shop item page
	"shop-item-type": "Type of clothes",
	"shop-item-collection": "Collection",
	"shop-item-description": "Description",
	"shop-item-size": "Available sizes",
	"shop-item-quantity": "Quantity",
	"shop-item-price": "Price",
	"shop-item-back-button": "back to shop",
	"shop-item-add-button": "add to cart",
	"shop-item-size-error": "Pick desired size.",
	"shop-item-quantity-error": "Pick desired quantity.",
	"shop-item-quantity-error-min": "Minimum quantity can be 1.",
	"shop-item-quantity-error-max": "Maximum quantity can be 10.",
	"shop-item-quantity-error-number": "Quantity must be number.",
	// cart page
	"cart-welcome-title": "Your",
	"cart-welcome-title-span": "cart",
	"cart-welcome-description": "Buy what you have picked.",
	"cart-info-title": "Cart items",
	"cart-info-title-bold": "product quantity",
	"cart-card-collection": "Collection",
	"cart-card-size": "Size",
	"cart-card-price": "Price",
	"cart-card-quantity": "Quantity",
	"cart-card-view": "View",
	"cart-card-remove": "Remove",
	"cart-send-button": "send order",
	"cart-empty": "Your cart is empty.",
	"cart-added-notification": "Your product has been added to the cart.",
	// gallery page
	"gallery-welcome-title-span": "gallery",
	"gallery-welcome-description": "Fall in love with our products :).",
	"gallery-counter-from": "of",
	"gallery-counter-images": "images",
	// contact page
	"contact-welcome-title": "Contact",
	"contact-welcome-title-span": "us",
	"contact-welcome-description": "If you are satisfied with the products you have seen, or if you have any questions, please contact us. Our team will provide you with the necessary information as soon as possible.",
	"contact-name-label": "Name",
	"contact-name-placeholder": "Enter full name",
	"contact-email-label": "Email",
	"contact-email-placeholder": "Enter email",
	"contact-subject-label": "Subject",
	"contact-subject-placeholder": "Enter subject",
	"contact-message-label": "Message",
	"contact-message-placeholder": "Enter message",
	"contact-send-button": "send message",
	"contact-message-sent-notification": "Your message is sent.",
	"contact-name-error": "Please insert full name.",
	"contact-email-error": "Please insert valid email.",
	"contact-subject-error": "Please insert subject.",
	"contact-message-error": "Please insert message.",
	// order modal
	"order-modal-title": "Sending order",
	"order-modal-full-name-label": "Full Name",
	"order-modal-name-placeholder": "Enter full name",
	"order-modal-phone-label": "Phone",
	"order-modal-phone-placeholder": "Enter phone",
	"order-modal-email-label": "Email",
	"order-modal-email-placeholder": "Enter email",
	"order-modal-address-city-label": "Address & City",
	"order-modal-address-placeholder": "Enter address & city",
	"order-modal-table-product": "Product",
	"order-modal-table-price": "Price",
	"order-modal-table-total": "Total",
	"order-modal-send-button": "Send",
	"order-modal-name-error": "Please insert full name.",
	"order-modal-phone-error": "Please insert phone.",
	"order-modal-email-error": "Please insert valid email.",
	"order-modal-address-error": "Please insert address & city.",
	"order-modal-send-title": "Your order has been sent!",
	"order-modal-send-description": "Someone from our store will contact you soon.",
	"order-modal-home-button": "home page",
	"order-modal-tc-label": "I agree with ",
	"order-modal-tc-label-link": "Terms & Conditions",
	"order-modal-tc-error": "You must agree with Terms & Conditions",
	// 404
	"error-not-found": "Page not found.",
	"error-home-button": "home page",
	// terms & conditions
	"tc-title": "Terms & Conditions",
	"tc-leading-message": "Please, read our terms & conditions before you buy our products.",
	"tc-sending-package-title": "Sending a package",
	"tc-sending-package-description-1": "The price of delivery is € 3.5 . The total time for which the shipment will be delivered to you from the moment of registration of your order is 3-5 (three to five) working days. Couriers deliver shipments to the delivery address between 8:00 a.m. and 5:00 p.m. Please ensure that there is a person at the address during that period who can collect the shipment. When picking up the shipment, you need to visually inspect the package to make sure there is no visible damage. If you notice that the shipping box is significantly damaged and you suspect that the product is damaged, refuse to accept the shipment and notify us immediately.",
	"tc-sending-package-description-2": "If the shipment is obviously undamaged, feel free to collect the shipment and register in the courier's address book. Except in the case of cash on delivery, you do not pay anything to the courier when picking up the shipment because you have already paid everything via the purchase order. The courier tries to deliver each one at a time. The usual practice is that if the courier does not find you at the address, he will call the phone number you left when creating the order and redirect to pick up the package at your post office.",
	"tc-complaints-title": "Complaints",
	"tc-complaints-description-1": "Valore Intimo will eliminate all errors and defects in the goods for which it is responsible. The customer can request the repair of the goods, then the replacement of the advertised goods and finally the refund. The customer does not have the right to complain and/or complain if: he missed the deadline for filing a complaint, the defect in the purchased product was caused by his fault or the fault of others, he bought the product on sale, bought a defective product at a reduced price or changed his decision on the choice of item. For goods purchased through this site, the customer can make a complaint via e-mail shop@valoreintimo.com, and Valore Intimo is obliged to respond to the complaint within 15 days of receiving the complaint, with a proposal for its resolution.",
	"tc-complaints-description-2": "When submitting a complaint, the buyer is obliged to state the reasons for advertising the goods, the advertised goods and the invoice proving that he bought the advertised goods. The product you are returning must be clean and the error must be marked. A dirty product will not be accepted. Valore Intimo assumes no responsibility for physically damaged products or products damaged by improper use. Rest assured that we will accept all valid complaints and, depending on your requirements, refund, replace or repair the product. Complaints, as well as detailed information on the rights and obligations of the buyer or seller, can be requested by calling the number +381 64 200 94 11 or by sending an e-mail to the address shop@valoreintimo.com. The ordered article cannot be changed if it has been worn, for hygiene reasons if the product belongs to the underwear group.",
	"tc-return-and-exchange-title": "Return and exchange",
	"tc-return-and-exchange-description-1": "If you are not satisfied with the purchased product, you have the right to exchange it with the attached invoice that you received at the postal address by calling the Valore Intimo contact phone: +381 64 200 94 11 within 14 days, where you will receive a response with detailed instructions on the exchange procedure. In case of exchange of goods, Valore Intimo does not bear the return costs. For any exchange, you are responsible for return shipping costs.",
	"tc-return-and-exchange-description-2": "If after receiving the item you tried it on (which does not apply to intimate underwear) and found that the product is too big or too small for you, you have the right to exchange it for the attached invoice that you received at the postal address by calling the Valore Intimo contact phone +381 64 200 94 11 within 14 days, where you will receive a reply with detailed instructions on the replacement procedure. In case of exchange of goods, Valore Intimo does not bear the return costs. For any exchange, you are responsible for return shipping costs. The ordered article cannot be exchanged if it has been worn, damaged in any way or is from the group of underwear products, for hygiene reasons.",
};