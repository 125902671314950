export const rs = {
  // global
  "page-about-us": "O nama",
  "page-collections": "Kolekcije",
  "page-shop": "Prodavnica",
  "page-cart": "Korpa",
  "page-gallery": "Galerija",
  "page-contact": "Kontakt",
  "loading": "učitavanje...",
  // header
  "page-description": "Saznajte osnovne informacije.",
  "page-about-us-description": "Saznajte više o nama, ko smo i šta nudimo.",
  "page-collections-description": "Otkrijte naše kolekcije.",
  "page-shop-description": "U prodavnici možete kupiti naše proizvode, filtrirati ih i pronaći sve informacije o njima.",
  "page-cart-description": "Proverite korpu i nastavite ka porudžbini.",
  "page-gallery-description": "Zaljubite se u naše proizvode :).",
  "page-contact-description": "Ne ustručavajte se da nas kontaktirate, tu smo da odgovorimo na sva pitanja.",
  // footer
  "footer-navigation-title": "Navigacija",
  "footer-contact-us-title": "Kontaktirajte nas",
  "footer-follow-us-title": "Zapratite nas",
  "copyright": "Sva prava zadržana.",
  // home page
  "home-welcome": "Vreme je da volite vašu odeću!",

  "home-about-title": "Šta je",
  "home-about-paragraph-1": "Valore Intimo predstavlja kompaniju koja se uglavnom bavi proizvodnjom jedinstvenog brenda donjeg veša. Pored proizvodnje i prodaje donjeg veša, Valore Intimo nudi i asortiman dečije odeće, kao i trenerke i dukseve za odrasle.",
  "home-about-paragraph-2": "Pored aktuelnih dizajna, Valore Intimo nastoji da konstantno obezbeđuje novi dizajn donjeg veša, kao i da kreira jedinstven dizajn odeće za sve uzraste. Zahvaljujući stručnom timu, možete pronaći kvalitetne proizvode po povoljnim cenama.",
  "home-about-paragraph-3": "Zahvaljujući jedinstvenom brendu, Valore Intimo se sve više probija na svetsko tržište sa velikim povećanjem prodaje svojih proizvoda. Na ovom sajtu možete pronaći sve informacije o nama, kao i o uslugama i proizvodima koje nudimo.",

  "home-top-product-title": "Naši",
  "home-top-product-title-span": "top proizvodi",

  "home-collections-title-span": "kolekcije",
  "home-collections-paragraph-1": "Tokom dugogodišnjeg rada, naša kompanija je uspela da proširi proizvodnju na sve ciljne grupe i da kreira kolekcije - muške, ženske i dečije. Naš asortiman se vremenom povećava, a svakog kvartala možete očekivati nove dizajne u svim kolekcijama. Našim proizvodima možete da obradujete svoje najmilije, kojima možete poručiti poklon preko naše prodavnice.",
  "home-collection-item-man": "muška",
  "home-collection-item-woman": "ženska",
  "home-collection-item-child": "dečija",
  "home-collection-item-t-shirt": "majica",
  "home-collection-item-sweatsuit": "trenerka",
  "home-collection-item-sweatshirt": "dukserica",
  "home-collection-item-underwear": "donji veš",
  "home-collection-item-button": "otkrijte sada",

  "home-best-seller-title": "Naš",
  "home-best-seller-title-span": "najprodavaniji proizvod",
  "home-best-seller-material-title": "Materijal",
  "home-best-seller-material-description": "Za proizvodnju naših proizvoda koriste se isključivo vrhunski materijali, tkani od najfinijeg pamuka, što omogućava nesmetanu aktivnost danju i noću.",
  "home-best-seller-quality-title": "Kvalitet",
  "home-best-seller-quality-description": "Da bismo obezbedili maksimalnu trajnost naših proizvoda, svaki naš proizvod je adekvatno obrađen i testiran.",
  "home-best-seller-adaptability-title": "Prilagodljivost",
  "home-best-seller-adaptability-description": "U našem asortimanu možete pronaći proizvode za sva godišnja doba, kako biste se osećali prijatno i nesmetano kretali tokom zime, kišnih i toplih sunčanih dana, kao i tokom dana provedenih u udobnosti svog doma.",
  "home-best-seller-idea-title": "Ideja",
  "home-best-seller-idea-description": "Vizija je da ponudimo nešto potpuno novo u moru istih proizvoda, novi pogled na svet dizajna intimnog donjeg veša i istovremeno posebno i prilagođeno svakom uzrastu.",
  "home-best-seller-button": "kupite sada",
  // about us page
  "about-us-welcome-title": "Informacije o",
  "about-us-welcome-description": "Istražite više o našoj kompaniji.",

  "about-us-basic-title": "Šta je",
  "about-us-basic-paragraph-1": "Valore Intimo je brend vođen idejom da kupcima ponudi \"drugačije\" iskustvo kupovine. Firma se prvenstveno bavi proizvodnjom donjeg veša. Pored donjeg veša, u ponudi je i širok asortiman odeće za decu i odrasle (majice, duksevi, trenerke, čarape itd.).",
  "about-us-basic-paragraph-2": "Vizija je da ponudimo nešto potpuno novo u moru istih proizvoda, novi pogled na svet dizajna intimnog donjeg veša i istovremeno posebno i prilagođeno svakom uzrastu.",
  "about-us-basic-paragraph-3": "Svaki naš proizvod je pažljivo dizajniran, montiran i testiran, od konca, materijala, preko štampe pa sve do pakovanja. Za proizvodnju naših proizvoda koriste se isključivo vrhunski materijali, tkani od najfinijeg pamuka, što omogućava nesmetanu aktivnost danju i noću.",
  "about-us-basic-paragraph-4": "Uz jedan deo našeg proizvoda koji dođe u vaše ruke, dolazi mnogo truda, energije i ljubavi, uloženih da bismo vam preneli svaki detalj naše ideje.",

  "about-us-timeline-title": "Naša",
  "about-us-timeline-title-span": "vremenska linija",
  "about-us-timeline-item-1-title": "Finalni proizvod",
  "about-us-timeline-item-1-description": "U 2022. godini obratili smo pažnju na odevne kombinacije namenjene svakodnevnoj upotrebi, kako bi sve vaše aktivnosti protekle nesmetano zahvaljujući udobnosti naših proizvoda. Pored proširenja programa, istovremeno se radilo i na unapređenju već postojećih proizvoda.",
  "about-us-timeline-item-2-title": "Proširenje tržišta",
  "about-us-timeline-item-2-date": "Septembar 2020",
  "about-us-timeline-item-2-description": "Posle 2 godine od osnivanja brenda, upotpunili smo program raznovrsnim dizajnom i proširili ga na muške, ženske i dečije majice, kao i dečje prsluke.",
  "about-us-timeline-item-3-title": "Naš prvi proizvod",
  "about-us-timeline-item-3-date": "Jul 2018",
  "about-us-timeline-item-3-description": "Posle višemesečnog istraživanja tržišta, izbor smo suzili na tekstilnu proizvodnju muškog i ženskog intimnog donjeg veša, od najfinijeg pamuka, dok smo u isto vreme ponudili novi pogled na svet dizajna intimnog veša.",
  "about-us-timeline-item-4-title": "Kada se rodila ideja?",
  "about-us-timeline-item-4-date": "Novembar 2017",
  "about-us-timeline-item-4-description": "Valore Intimo je brend vođen idejom da kupcima ponudi „drugačije“ iskustvo kupovine. Prva ideja je bila da pokrenemo nešto po čemu bismo bili prepoznatljivi i drugačiji od drugih. Šta možemo ponuditi kupcima kako bi upotpunili dan pun aktivnosti?",
  // collections page
  "collections-welcome-title-man": "Muška kolekcija",
  "collections-welcome-title-woman": "Ženska kolekcija",
  "collections-welcome-title-child": "Dečija kolekcija",
  "collections-welcome-button": "istražite",

  "collections-man-title-span": "Muška",
  "collections-man-title": "kolekcija",
  "collections-man-paragraph-1": "U našem asortimanu možete pronaći mušku odeću zanimljivog dizajna, od najfinijeg pamuka. Svakom proizvedenom komadu posvećujemo odgovarajuću pažnju, kako bismo zadovoljili svakog kupca njegovim karakteristikama.",
  "collections-man-paragraph-2": "Mušku kolekciju čine zanimljivi dezeni, koji upotpunjuju njihov izgled i izdvajaju ih iz standardne svakodnevne ponude. Pored intimne odeće, nudimo i niz majica, proizvedenih od kvalitetnog pamuka.",
  "collections-man-paragraph-3": "U ponudi imamo i dukseve, kao i komplete trenerki koje će upotpuniti vaš izgled u promenljivim i hladnim vremenskim uslovima.",
  "collections-woman-title-span": "Ženska",
  "collections-woman-title": "kolekcija",
  "collections-woman-paragraph-1": "Ženskoj intimnoj odeći posvećuje se adekvatna pažnja, kako bi se i najsitniji detalji doveli do savršenstva i tako omogućio maksimalan komfor tokom svakodnevnih aktivnosti.",
  "collections-woman-paragraph-2": "U ponudi imamo ležerne i slim modele majica, kao i duple komplete trenerki koje omogućavaju nesmetanu aktivnost i omogućavaju lakoću kretanja.",
  "collections-woman-paragraph-3": "Takođe, svoj stajling možete upotpuniti oversize duksericama u opuštenoj dnevnoj verziji, gde ćete sa lakoćom savladati svaki izazov.",
  "collections-child-title-span": "Dečija",
  "collections-child-title": "kolekcija",
  "collections-child-paragraph-1": "Dečji program nudi raznovrsnost, jer jednostavnost nije opcija da bi se deca zaintrigirala.",
  "collections-child-paragraph-2": "Kontinuirana aktivnost tokom dana ili noći ne sme biti ograničena neadekvatnim proizvodima koji sprečavaju njihovu slobodu kretanja.",
  "collections-child-paragraph-3": "Sve za decu je samo početak ponude, koja im izmami osmeh na sam pogled na zanimljive printove, kojima ne mogu da odole ni stariji. Kako bismo našim mališanima pokazali šta je udobnost i nesmetana aktivnost, naš program smo upotpunili majicama raznih dezena, kao i kompletima trenerki od kojih jednostavno ne žele da se rastanu.",
  // shop page
  "shop-welcome-title-span": "prodavnica",
  "shop-welcome-description": "Otkrijte i kupujte razne proizvode u našoj prodavnici.",

  "shop-filters": "Filteri",
  "shop-products": "Proizvodi",
  "shop-filters-title": "Naziv",
  "shop-filters-title-placeholder": "Pretraga po nazivu",
  "shop-filters-size": "Veličina",
  "shop-filters-type": "Vrsta",
  "shop-filters-type-underwear": "donji veš",
  "shop-filters-type-t-shirt": "majica",
  "shop-filters-type-sweatshirt": "duks",
  "shop-filters-type-sweatsuit": "trenerka",
  "shop-filters-type-top": "top",
  "shop-filters-type-pajamas": "pidžama",
  "shop-filters-collection": "Kolekcija",
  "shop-filters-collection-man": "muška",
  "shop-filters-collection-woman": "ženska",
  "shop-filters-collection-child": "dečija",
  "shop-filters-button": "resetuj filtere",

  "shop-no-products": "Nema proizvoda za prikaz.",

  "shop-product-collection-man": "muška",
  "shop-product-collection-woman": "ženska",
  "shop-product-collection-child": "dečija",

  "shop-product-type-underwear": "donji veš",
  "shop-product-type-t-shirt": "majica",
  "shop-product-type-sweatshirt": "duks",
  "shop-product-type-sweatsuit": "trenerka",
  "shop-product-type-top": "top",
  "shop-product-type-pajamas": "pidžama",
  // shop data
	"mark-new": "novo",
	"mark-sold": "prodato",
  "underwear-mole": "Donji Veš Krtice",
  "underwear-schnauzer": "Donji Veš Šnaucer",
  "underwear-gray": "Donji Veš Sivi",
  "underwear-rooster": "Donji Veš Petao",
  "underwear-yellow": "Donji Veš Žuti",
	"underwear-snails": "Donji Veš Puževi",
	"underwear-horses": "Donji Veš Konji",
	"underwear-birds": "Donji Veš Ptičice",
	"underwear-red-frogs": "Donji Veš žabe",
	"underwear-mongooses": "Donji Veš Mungosi",
	"underwear-giraffes": "Donji Veš Žirafe",
	"underwear-mosquitoes": "Donji Veš Komarci",
  "underwear-boars": "Donji Veš Veprovi",
	"underwear-hedgehogs": "Donji Veš Ježevi",
	"underwear-turtles": "Donji Veš Kornjače",
	"underwear-half-boxers-dark-blue": "Donji Veš Bokserice Teget",
  "underwear-half-boxers-hen": "Donji Veš Bokserice Koka",
  "underwear-half-boxers-tufted": "Donji Veš Bokserice Čupavci",
  "underwear-half-boxers-flamingos": "Donji Veš Bokserice Flamingos",
  "underwear-half-boxers-amarant": "Donji Veš Bokserice Amarant",
  "underwear-half-boxers-maroon": "Donji Veš Bokserice Bordo",
  "underwear-half-boxers-olive-green": "Polu Bokserice Maslinasto Zelene",
  "underwear-half-boxers-dark-brown": "Polu Bokserice Tamno Braon",
  "underwear-half-boxers-scooter": "Polu Bokserice Vespa",
  "underwear-triangles": "Donji Veš Trouglovi",
  "underwear-boats": "Donji Veš Čamci",
  "underwear-blue": "Donji Veš Plavi",
  "underwear-amarant": "Donji Veš Amarant",
	"underwear-maroon": "Donji Veš Bordo",
	"underwear-soft-pink": "Donji Veš Nežno Roze",
  "underwear-orange": "Donji Veš Narandžasti",
	"underwear-green": "Donji Veš Zeleni",
  "underwear-puppies": "Donji Veš Kuce",
	"underwear-tent": "Donji Veš Šator",
  "underwear-mustache": "Donji Veš Brke",
  "underwear-black": "Donji Veš Crni",
  "underwear-tufted": "Donji Veš Čupavci",
  "underwear-apricot": "Donji Veš Kajsija",
  "underwear-olive-green": "Donji Veš Maslinasto Zeleni",
  "underwear-fat-man": "Donji Veš Debeljko",
	"underwear-guitars": "Donji Veš Gitare",
  "underwear-ants": "Donji Veš Mravi",
	"thongs-purple": "Tange Ljubičaste",
	"thongs-orange": "Tange Narandžaste",
  "thongs-orange-light": "Tange Svetlo Narandžaste",
	"thongs-dark-green": "Tange Tamno Zelene",
	"thongs-birds": "Tange Ptičice",
	"thongs-green": "Tange Zelene",
	"thongs-pink": "Tange Pink",
	"thongs-brown": "Tange Braon",
	"thongs-yellow": "Tange Žute",
  "thongs-kerosene": "Tange Petrolej",
	"thongs-dark-blue": "Tange Teget",
  "thongs-hen": "Tange Koka",
  "thongs-amarant": "Tange Amarant",
	"thongs-maroon": "Tange Bordo",
	"thongs-soft-pink": "Tange Nežno Roze",
  "thongs-mint": "Tange Mint",
  "thongs-chickens": "Tange Pilići",
  "thongs-black-minimalistic": "Tange Crne Minimalističke",
  "thongs-tufted": "Tange Čupavci",
  "thongs-flamingo": "Tange Flamingo",
  "thongs-pink-2": "Tange Nude Pink",
  "thongs-scooter": "Tange Vespa",
  "thongs-dark-brown": "Tange Tamno Braon",
  "thongs-olive-green": "Tange Maslinasto Zelene",
	"brazilian-amarant": "Brazilke Amarant",
	"brazilian-maroon": "Brazilke Bordo",
	"brazilian-soft-pink": "Brazilke Nežno Roze",
	"brazilian-purple": "Brazilke Ljubičaste",
	"brazilian-orange": "Brazilke Narandžaste",
  "brazilian-brown": "Brazilke Braon",
	"brazilian-light-green": "Brazilke Svetlo Zelene",
	"brazilian-dark-green": "Brazilke Tamno Zelene",
	"brazilian-birds": "Brazilke Ptičice",
	"brazilian-yellow": "Brazilke Žute",
  "brazilian-panties-kerosene": "Brazilke Petrolej",
	"brazilian-panties-dark-blue": "Brazilke Teget",
  "brazilian-panties-hen": "Brazilke Koka",
  "brazilian-tufted": "Brazilke Čupavci",
  "brazilian-flamingo": "Brazilke Flamingo",
  "brazilian-mint": "Brazilke Mint",
  "brazilian-black-minimalistic": "Brazilke Crne Minimalističke",
  "brazilian-panties-olive-green": "Brazilke Maslinasto Zelene",
  "brazilian-panties-pink": "Brazilke Nude Pink",
  "brazilian-panties-dark-brown": "Brazilke Tamno Braon",
	"brazilian-panties-scooter": "Brazilke Vespa",
  "t-shirt-schnauzer": "Majica Šnaucer",
  "t-shirt-unisex-dog": "Majica Unisex Pas",
	"t-shirt-unisex-giraffe": "Majica Unisex Žirafa",
	"t-shirt-unisex-bear": "Majica Unisex Medved",
	"t-shirt-unisex-bird": "Majica Unisex Ptica",
  "t-shirt-slim-fit-black": "Majica Slim-Fit Crna",
  "t-shirt-chamois": "Majica Divokoza",
	"t-shirt-brown": "Majica Braon",
	"t-shirt-light-blue": "Majica Svetlo Plava",
  "t-shirt-moja-slavice": "Majica 'Moja Slavice'",
	"t-shirt-black": "Majica Crna",
	"t-shirt-dark-grey": "Majica Tamno Siva",
  "t-shirt-kerosene": "Majica Petrolej",
  "sweatsuit-unisex-light-green": "Trenerka Unisex Svetlo Zelena",
	"sweatsuit-unisex-dino": "Trenerka Unisex Dino",
	"sweatsuit-unisex-orange": "Trenerka Unisex Narandžasta",
  "sweatshirt-green-oversize": "Dukserica Zelena Oversize",
	"sweatshirt-black-half-slim": "Dukserica Crna Polu-slim",
	"sweatshirt-sloth-half-slim": "Dukserica Lenjivac Polu-slim",
	"sweatshirt-orange": "Dukserica Narandžasta",
	"sweatshirt-black": "Dukserica Crna",
  "sweatshirt-brown": "Dukserica Braon",
  "sweatshirt-apricot": "Dukserica Kajsija",
	"sweatsuit-double-face-orange": "Trenerka Double-Face Narandžasta",
	"sweatsuit-double-face-light": "Trenerka Double-Face Svetla",
	"sweatsuit-double-face-black": "Trenerka Double-Face Crna",
  "top-hen": "Top Koka",
  "top-olive-green": "Top Maslinasto Zeleni",
  "top-dark-brown": "Top Tamno Braon",
  "pajamas-pearl-dreamer": "Pidžama Biserna Sanjalica",
  "pajamas-children-pearl-dreamer": "Pidžama Dečija Biserna Sanjalica",
  // shop data description
	"description-sweatshirt-man": "Muški duksevi su klasičan model koji odgovara svim starosnim grupama. VALORE logo je kvalitetno odštampan na modelu.",
	"description-sweatshirt-man-half-slim": "Muški duksevi su klasičan model koji odgovara svim starosnim grupama. VALORE logo je kvalitetno odštampan na modelu.",
	"description-t-shirt-woman": "Ženske majice su napravljene od najfinijeg pamuka koji se prilagođava vašem telu i omogućava nesmetano kretanje. U ponudi imamo klasični (casual) model, kao i slim fit model.",
  "description-t-shirt-man": "Muške majice su napravljene od najfinijeg pamuka koji se prilagođava vašem telu i omogućava nesmetano kretanje.",
  "description-sweatshirt-woman": "Ženske dukserice su napravljene od najfinijeg pamuka koji se prilagođava vašem telu i omogućava nesmetano kretanje.",
	"description-sweatsuit-child": "Dečije trenerke su klasičan model koji odgovara svim starosnim grupama. Uz zabavne ilustracije, vaše dete će biti zainteresovano i rado će ga nositi.",
	"description-sweatsuit-man-double-face": "Muške trenerke su klasičan model koji odgovara svim starosnim grupama. VALORE logo je kvalitetno odštampan na modelu.",
	"description-sweatsuit-woman-double-face": "Ženske trenerke su klasičan model koji odgovara svim starosnim grupama. VALORE logo je kvalitetno odštampan na modelu.",
	"description-t-shirt-child": "Dečije majice su klasičan model koji odgovara svim starosnim grupama. Uz zabavne ilustracije, vaše dete će biti zainteresovano i rado će ga nositi.",
	"description-underwear-brazilian": "Ženski brazilski donji veš je klasičan model koji odgovara svim starosnim grupama. Pojas sa pečatom VALORE se nalazi na svakom modelu koji je ojačan centralnim delom.",
	"description-underwear-man": "Muške bokserice su klasičan model koji odgovara svim starosnim grupama. Pojas sa utisnutim VALORE-om se nalazi na svakom modelu koji je ojačan centralnim delom.",
	"description-underwear-woman": "Ženske tange su klasičan model koji odgovara svim starosnim grupama. Pojas sa utisnutim VALORE-om se nalazi na svakom modelu koji je ojačan centralnim delom.",
	"description-material-global": "Mašinsko pranje do 60 stepeni. Upotreba izbeljivača je zabranjena. Veličina etikete je svilena, kako bi se pružila dodatna udobnost. Materijal:",
  "description-top": "Ženski top je klasičan model koji odgovara svim starosnim grupama. Pojas sa pečatom VALORE se nalazi na svakom modelu. Ne sadrži umetak.",
  "description-pajamas-woman": "Ženske pidžame su napravljene od najfinijeg materijala koji se prilagođava vašem telu i omogućava nesmetano kretanje.",
  "description-pajamas-children": "Dečije pidžame su napravljene od najfinijeg materijala koji se prilagođava vašem telu i omogućava nesmetano kretanje.",
	"description-cotton": "Pamuk",
	"description-elastine": "Elastin",
	"description-size": "Veličina",
	"description-inches": "inči",
  "description-type": "tip",
	"description-sweatshirt-height": "Visina dukserica",
	"description-sweatshirt-width": "Širina dukserice",
	"description-sweatshirt": "DUKSERICA",
  "description-pajamas-top-part": "Gornji deo",
  "description-pajamas-top-part-height": "Visina gornjeg dela pidžame",
	"description-pajamas-top-part-width": "Širina gornjeg dela pidžame",
  "description-pajamas-bottom-part": "Donji deo",
	"description-pajamas-bottom-part-height": "Visina donjeg dela pidžame",
	"description-pajamas-bottom-part-width": "Širina donjeg dela pidžame",
	"description-pajamas": "PIDŽAME",
	"description-tracksuit": "TRENERKA",
	"description-tracksuit-height": "Visina trenerke",
	"description-tracksuit-width": "Širina trenerke",
	"description-t-shirt-height": "Visina majice",
	"description-t-shirt-width": "Širina majice",
	"description-waist": "Struk",
  "chest-circumference": "Obim grudi",
	"description-hips": "Kukovi",
	"description-waist-size": "Širina struka",
	"description-thigh-size": "Veličina butina",
  // shop item page
  "shop-item-type": "Vrsta odeće",
  "shop-item-collection": "Kolekcija",
  "shop-item-description": "Opis",
  "shop-item-size": "Dostupne veličine",
  "shop-item-quantity": "Količina",
  "shop-item-price": "Cena",
  "shop-item-back-button": "nazad u prodavnicu",
  "shop-item-add-button": "dodaj u korpu",
  "shop-item-size-error": "Odaberite odgovarajuću veličinu.",
  "shop-item-quantity-error": "Odaberite odgovarajuću količinu.",
  "shop-item-quantity-error-min": "Minimalna količina mora biti 1.",
	"shop-item-quantity-error-max": "Maksimalna količina može biti 10.",
	"shop-item-quantity-error-number": "Količina mora biti broj.",
  // cart page
  "cart-welcome-title": "Vaša",
  "cart-welcome-title-span": "korpa",
  "cart-welcome-description": "Kupite ono što ste odabrali.",
  "cart-info-title": "Proizvodi iz korpe",
  "cart-info-title-bold": "količina proizvoda",
  "cart-card-collection": "Kolekcija",
  "cart-card-size": "Veličina",
  "cart-card-price": "Cena",
  "cart-card-quantity": "Količina",
  "cart-card-view": "Pogledaj",
  "cart-card-remove": "Ukloni",
  "cart-send-button": "pošalji porudžbinu",
  "cart-empty": "Vaša korpa je prazna.",
  "cart-added-notification": "Vaš proizvod je dodat u korpu.",
  // gallery page
  "gallery-welcome-title-span": "galerija",
  "gallery-welcome-description": "Zaljubite se u naše proizvode :).",
  "gallery-counter-from": "od",
  "gallery-counter-images": "fotografija",
  // contact page
  "contact-welcome-title": "Kontaktirajte",
  "contact-welcome-title-span": "nas",
  "contact-welcome-description": "Ako ste zadovoljni proizvodima koje ste videli, ili ako imate bilo kakvih pitanja, kontaktirajte nas. Naš tim će vam pružiti potrebne informacije u najkraćem mogućem roku.",
  "contact-name-label": "Ime",
  "contact-name-placeholder": "Unesite ime i prezime",
  "contact-email-label": "Email",
  "contact-email-placeholder": "Unesite email",
  "contact-subject-label": "Naslov poruke",
  "contact-subject-placeholder": "Unesite naslov poruke",
  "contact-message-label": "Poruka",
  "contact-message-placeholder": "Unesite poruku",
  "contact-send-button": "pošaljite poruku",
  "contact-message-sent-notification": "Vaša poruka je poslata.",
  "contact-name-error": "Unesite ime i prezime.",
	"contact-email-error": "Unesite ispravan email.",
	"contact-subject-error": "Unesite naslov poruke.",
	"contact-message-error": "Unesite poruku.",
  // order modal
	"order-modal-title": "Slanje porudžbine",
	"order-modal-full-name-label": "Ime i prezime",
	"order-modal-name-placeholder": "Unesite ime i prezime",
	"order-modal-phone-label": "Telefon",
	"order-modal-phone-placeholder": "Unesite telefon",
	"order-modal-email-label": "Email",
	"order-modal-email-placeholder": "Unesite email",
	"order-modal-address-city-label": "Adresa i grad",
	"order-modal-address-placeholder": "Unesite adresu i grad",
	"order-modal-table-product": "Proizvod",
	"order-modal-table-price": "Cena",
	"order-modal-table-total": "Ukupno",
	"order-modal-send-button": "Pošalji",
  "order-modal-name-error": "Unesite ime i prezime.",
	"order-modal-phone-error": "Unesite telefon.",
	"order-modal-email-error": "Unesite ispravan email.",
	"order-modal-address-error": "Unesite adresu i grad.",
  "order-modal-send-title": "Vaša porudžbina je poslata!",
  "order-modal-send-description": "Neko iz naše prodavnice će vas uskoro kontaktirati.",
	"order-modal-home-button": "početna strana",
  "order-modal-tc-label": "Slažem se sa ",
	"order-modal-tc-label-link": "uslovima korišćenja",
  "order-modal-tc-error": "Morate se složiti sa Uslovima korišćenja",
  // 404
	"error-not-found": "Stranica nije pronadjena.",
	"error-home-button": "početna strana",
  // terms & conditions
	"tc-title": "Uslovi korišćenja",
	"tc-leading-message": "Molimo vas da pročitate naše uslove i odredbe pre nego što kupite naše proizvode.",
	"tc-sending-package-title": "Slanje paketa",
	"tc-sending-package-description-1": "Cena dostave je 400 rsd. Ukupno vreme za koje će vam pošiljka biti isporučena od trenutka registracije vaše porudžbine je 3-5 (tri do pet) radnih dana. Kuriri dostavljaju pošiljke na adresu za dostavu u periodu od 8:00 do 17:00 časova. Molimo vas da u tom periodu na adresi bude osoba koja može preuzeti pošiljku. Prilikom preuzimanja pošiljke potrebno je da vizuelno pregledate paket da biste bili sigurni da nema vidljivih oštećenja. Ako primetite da je transportna kutija značajno oštećena i sumnjate da je proizvod oštećen, odbijte da primite pošiljku i odmah nas obavestite.",
	"tc-sending-package-description-2": "Ako je pošiljka očigledno neoštećena, slobodno preuzmite pošiljku i upišite se u adresar kurira. Osim u slučaju pouzeća, prilikom preuzimanja pošiljke ne plaćate ništa kuriru jer ste sve već platili putem narudžbenice. Kurir pokušava da isporuči svaku jednom. Uobičajena praksa je da ukoliko Vas kurir ne pronađe na adresi, pozove na broj telefona koji ste ostavili prilikom kreiranja porudžbine i preusmeri na preuzimanje paketa u Vašoj pošti.",
	"tc-complaints-title": "Žalbe",
	"tc-complaints-description-1": "Valore Intimo će otkloniti sve greške i nedostatke u robi za koje je odgovoran. Kupac može zahtevati popravku robe, zatim zamenu reklamirane robe i na kraju povraćaj novca. Kupac nema pravo reklamacije i/ili prigovora ako: je propustio rok za podnošenje reklamacije, nedostatak na kupljenom proizvodu je nastao njegovom krivicom ili krivicom drugih, kupio je proizvod na prodaju, kupio neispravan proizvod po sniženoj ceni ili promenio odluku o izboru artikla. Za robu kupljenu preko ovog sajta kupac može izvršiti reklamaciju putem e-maila shop@valoreintimo.com, a Valore Intimo je u obavezi da odgovori na reklamaciju u roku od 15 dana od prijema reklamacije, sa predlogom za njegovo rešavanje.",
	"tc-complaints-description-2": "Prilikom podnošenja reklamacije, kupac je dužan da navede razloge zbog kojih reklamira robu, reklamiranu robu i račun kojim dokazuje da je reklamiranu robu kupio. Proizvod koji vraćate mora biti čist i greška mora biti označena. Prljavi proizvod neće biti prihvaćen. Valore Intimo ne preuzima nikakvu odgovornost za fizički oštećene proizvode ili proizvode koji su oštećeni nepravilnom upotrebom. Budite sigurni da ćemo prihvatiti sve opravdane reklamacije i, u zavisnosti od vaših zahteva, refundirati, zameniti ili popraviti proizvod. Reklamacije, kao i detaljne informacije o pravima i obavezama kupca ili prodavca, mogu se zatražiti pozivom na broj +381 64 200 94 11 ili slanjem e-maila na adresu shop@valoreintimo.com. Naručeni artikal se ne može menjati ako je nošen, iz higijenskih razloga ako proizvod pripada grupi donjeg veša.",
	"tc-return-and-exchange-title": "Povratak i zamena",
	"tc-return-and-exchange-description-1": "Ukoliko niste zadovoljni kupljenim proizvodom, imate pravo da ga zamenite uz priloženu fakturu koju ste dobili na poštansku adresu pozivom na kontakt telefon Valore Intimo: +381 64 200 94 11 u roku od 14 dana, gde ćete dobiti odgovor sa detaljnim uputstva o postupku razmene. U slučaju zamene robe, Valore Intimo ne snosi troškove vraćanja. Za svaku zamenu, snosite troškove povratne isporuke.",
	"tc-return-and-exchange-description-2": "Ako ste nakon prijema artikla isprobali (što se ne odnosi na intimni veš) i utvrdili da vam je proizvod prevelik ili premali, imate pravo da ga zamenite za priloženu fakturu koju ste dobili na poštansku adresu pozivom na kontakt telefon Valore Intimo +381 64 200 94 11 u roku od 14 dana, gde ćete dobiti odgovor sa detaljnim uputstvom o postupku zamene. U slučaju zamene robe, Valore Intimo ne snosi troškove vraćanja. Za svaku zamenu, snosite troškove povratne isporuke. Naručeni artikal se ne može zameniti ako je nošen, oštećen na bilo koji način ili je iz grupe proizvoda donjeg veša, iz higijenskih razloga.",
};