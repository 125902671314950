export const shopData = (t) => {
  const data = [
    // added on December 27 2024
    {
      id: "118",
      title: t('pajamas-children-pearl-dreamer'),
      mark: t('mark-new'),
      description: 'pajamas-children',
      leadingImage: "/images/shop/118/valore-pajamas-children-pearl-dreamer-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/118/valore-pajamas-children-pearl-dreamer-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/118/valore-pajamas-children-pearl-dreamer-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-pajamas-children-sizes.jpg",
        },
      ],
      size: ["2", "4", "6", "8", "10"],
      collection: t("shop-product-collection-child"),
      type: t("shop-product-type-pajamas"),
      availableQuantity: 10,
      priceRSD: 2590,
      priceEURO: 22.5,
    },
    {
      id: "117",
      title: t('pajamas-pearl-dreamer'),
      mark: t('mark-new'),
      description: 'pajamas-woman',
      leadingImage: "/images/shop/117/valore-pajamas-pearl-dreamer-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/117/valore-pajamas-pearl-dreamer-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/117/valore-pajamas-pearl-dreamer-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-womens-pajamas-sizes.jpg",
        },
      ],
      size: ["xs", "s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-pajamas"),
      availableQuantity: 10,
      priceRSD: 3600,
      priceEURO: 31,
    },
    {
      id: "116",
      title: t('thongs-olive-green'),
      mark: t('mark-new'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/116/valore-thongs-olive-green-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/116/valore-thongs-olive-green-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/116/valore-thongs-olive-green-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-thongs-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "115",
      title: t('thongs-dark-brown'),
      mark: t('mark-new'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/115/valore-thongs-dark-brown-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/115/valore-thongs-dark-brown-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/115/valore-thongs-dark-brown-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-thongs-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "114",
      title: t('thongs-scooter'),
      mark: t('mark-new'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/114/valore-thongs-scooter-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/114/valore-thongs-scooter-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/114/valore-thongs-scooter-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-thongs-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "113",
      title: t('thongs-pink-2'),
      mark: t('mark-new'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/113/valore-thongs-pink-2-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/113/valore-thongs-pink-2-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/113/valore-thongs-pink-2-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-thongs-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "112",
      title: t('underwear-half-boxers-scooter'),
      mark: t('mark-new'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/112/valore-underwear-half-boxers-scooter-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/112/valore-underwear-half-boxers-scooter-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/112/valore-underwear-half-boxers-scooter-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/112/valore-underwear-half-boxers-scooter-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-half-boxers-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "111",
      title: t('underwear-half-boxers-dark-brown'),
      mark: t('mark-new'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/111/valore-underwear-half-boxers-dark-brown-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/111/valore-underwear-half-boxers-dark-brown-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/111/valore-underwear-half-boxers-dark-brown-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/111/valore-underwear-half-boxers-dark-brown-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-half-boxers-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "110",
      title: t('underwear-half-boxers-olive-green'),
      mark: t('mark-new'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/110/valore-underwear-half-boxers-olive-green-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/110/valore-underwear-half-boxers-olive-green-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/110/valore-underwear-half-boxers-olive-green-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/110/valore-underwear-half-boxers-olive-green-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-half-boxers-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "109",
      title: t('brazilian-panties-scooter'),
      mark: t('mark-new'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/109/valore-brazilian-panties-scooter-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/109/valore-brazilian-panties-scooter-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/109/valore-brazilian-panties-scooter-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-brazilian-panties-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "108",
      title: t('brazilian-panties-dark-brown'),
      mark: t('mark-new'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/108/valore-brazilian-panties-dark-brown-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/108/valore-brazilian-panties-dark-brown-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/108/valore-brazilian-panties-dark-brown-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/108/valore-brazilian-panties-dark-brown-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-brazilian-panties-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "107",
      title: t('brazilian-panties-pink'),
      mark: t('mark-new'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/107/valore-brazilian-panties-pink-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/107/valore-brazilian-panties-pink-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/107/valore-brazilian-panties-pink-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-brazilian-panties-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "106",
      title: t('brazilian-panties-olive-green'),
      mark: t('mark-new'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/106/valore-brazilian-panties-olive-green-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/106/valore-brazilian-panties-olive-green-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/106/valore-brazilian-panties-olive-green-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-brazilian-panties-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "105",
      title: t('underwear-ants'),
      mark: t('mark-new'),
      description: 'underwear-man',
      leadingImage: "/images/shop/105/valore-underwear-ants-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/105/valore-underwear-ants-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/sizes/valore-underwear-man-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "104",
      title: t('underwear-guitars'),
      mark: t('mark-new'),
      description: 'underwear-man',
      leadingImage: "/images/shop/104/valore-underwear-guitars-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/104/valore-underwear-guitars-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/sizes/valore-underwear-man-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "103",
      title: t('underwear-fat-man'),
      mark: t('mark-new'),
      description: 'underwear-man',
      leadingImage: "/images/shop/103/valore-underwear-fat-man-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/103/valore-underwear-fat-man-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/sizes/valore-underwear-man-sizes.jpg",
        },
      ],
      size: ["m"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "102",
      title: t('top-dark-brown'),
      mark: t('mark-new'),
      description: 'top-woman',
      leadingImage: "/images/shop/102/valore-top-dark-brown-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/102/valore-top-dark-brown-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/102/valore-top-dark-brown-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-top-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-top"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "101",
      title: t('top-olive-green'),
      mark: t('mark-new'),
      description: 'top-woman',
      leadingImage: "/images/shop/101/valore-top-olive-green-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/101/valore-top-olive-green-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/101/valore-top-olive-green-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-top-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-top"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // added on November 1 2024
    {
      id: "100",
      title: t("sweatshirt-apricot"),
      description: 'sweatshirt-man2',
      leadingImage: "/images/shop/100/valore-sweatshirt-apricot-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/100/valore-sweatshirt-apricot-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/100/valore-sweatshirt-apricot-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/100/valore-sweatshirt-apricot-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-man-sweatshirt-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-sweatshirt"),
      availableQuantity: 10,
      priceRSD: 2900,
      priceEURO: 25,
    },
    {
      id: "99",
      title: t("sweatshirt-brown"),
      description: 'sweatshirt-man2',
      leadingImage: "/images/shop/99/valore-sweatshirt-brown-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/99/valore-sweatshirt-brown-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/99/valore-sweatshirt-brown-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-man-sweatshirt-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-sweatshirt"),
      availableQuantity: 10,
      priceRSD: 2900,
      priceEURO: 25,
    },
    {
      id: "98",
      title: t('underwear-olive-green'),
      description: 'underwear-man',
      leadingImage: "/images/shop/98/valore-underwear-olive-green-leading.jpeg",
      images: [
        {
          id: 0,
          src: "/images/shop/98/valore-underwear-olive-green-leading.jpeg",
        },
        {
          id: 1,
          src: "/images/shop/sizes/valore-underwear-man-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl", "3xl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "97",
      title: t('underwear-apricot'),
      description: 'underwear-man',
      leadingImage: "/images/shop/97/valore-underwear-apricot-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/97/valore-underwear-apricot-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/97/valore-underwear-apricot-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-underwear-man-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl", "3xl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "96",
      title: t('t-shirt-kerosene'),
      description: 't-shirt-man-cotton-100-percentage-2',
      leadingImage: "/images/shop/96/valore-t-shirt-kerosene-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/96/valore-t-shirt-kerosene-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/96/valore-t-shirt-kerosene-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-man-t-shirt-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-t-shirt"),
      availableQuantity: 10,
      priceRSD: 1790,
      priceEURO: 15.3,
    },
    // added on August 18 2024
    // {
    //   id: "95",
    //   title: t('underwear-tufted'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/95/valore-underwear-tufted-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/95/valore-underwear-tufted-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/95/valore-underwear-tufted-1.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/sizes/valore-underwear-man-sizes.jpg",
    //     },
    //   ],
    //   size: ["m", "l"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    {
      id: "94",
      title: t('underwear-black'),
      description: 'underwear-man',
      leadingImage: "/images/shop/94/valore-underwear-black-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/94/valore-underwear-black-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/sizes/valore-underwear-man-sizes.jpg",
        },
      ],
      size: ["m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "93",
      title: t('underwear-mustache'),
      description: 'underwear-man',
      leadingImage: "/images/shop/93/valore-underwear-mustache-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/93/valore-underwear-mustache-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/93/valore-underwear-mustache-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-underwear-man-sizes.jpg",
        },
      ],
      size: ["m"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "92",
      title: t('thongs-flamingo'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/92/valore-thongs-flamingo-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/92/valore-thongs-flamingo-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/92/valore-thongs-flamingo-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-thongs-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "91",
      title: t('thongs-tufted'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/91/valore-thongs-tufted-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/91/valore-thongs-tufted-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/91/valore-thongs-tufted-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-thongs-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "90",
      title: t('thongs-black-minimalistic'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/90/valore-thongs-black-minimalistic-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/90/valore-thongs-black-minimalistic-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/90/valore-thongs-black-minimalistic-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-thongs-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "89",
      title: t('thongs-chickens'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/89/valore-thongs-chickens-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/89/valore-thongs-chickens-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/89/valore-thongs-chickens-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-thongs-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "88",
      title: t('thongs-mint'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/88/valore-thongs-mint-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/88/valore-thongs-mint-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/88/valore-thongs-mint-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-thongs-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "87",
      title: t('underwear-half-boxers-maroon'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/87/valore-underwear-half-boxers-maroon-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/87/valore-underwear-half-boxers-maroon-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/87/valore-underwear-half-boxers-maroon-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/87/valore-underwear-half-boxers-maroon-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-half-boxers-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "86",
      title: t('underwear-half-boxers-amarant'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/86/valore-underwear-half-boxers-amarant-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/86/valore-underwear-half-boxers-amarant-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/86/valore-underwear-half-boxers-amarant-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-half-boxers-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "85",
      title: t('underwear-half-boxers-flamingos'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/85/valore-underwear-half-boxers-flamingos-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/85/valore-underwear-half-boxers-flamingos-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/85/valore-underwear-half-boxers-flamingos-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-half-boxers-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "84",
      title: t('underwear-half-boxers-tufted'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/84/valore-underwear-half-boxers-tufted-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/84/valore-underwear-half-boxers-tufted-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/84/valore-underwear-half-boxers-tufted-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/84/valore-underwear-half-boxers-tufted-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-half-boxers-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "83",
      title: t('brazilian-black-minimalistic'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/83/valore-brazilian-panties-black-minimalistic-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/83/valore-brazilian-panties-black-minimalistic-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/83/valore-brazilian-panties-black-minimalistic-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-brazilian-panties-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "82",
      title: t('brazilian-mint'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/82/valore-brazilian-panties-mint-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/82/valore-brazilian-panties-mint-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/82/valore-brazilian-panties-mint-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/82/valore-brazilian-panties-mint-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-brazilian-panties-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "81",
      title: t('brazilian-flamingo'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/81/valore-brazilian-panties-flamingo-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/81/valore-brazilian-panties-flamingo-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/81/valore-brazilian-panties-flamingo-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-brazilian-panties-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "80",
      title: t('brazilian-tufted'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/80/valore-brazilian-panties-tufted-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/80/valore-brazilian-panties-tufted-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/80/valore-brazilian-panties-tufted-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-brazilian-panties-sizes.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // added on May 12 2024
    // {
    //   id: "79",
    //   title: t('underwear-tent'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/79/valore-underwear-tent-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/79/valore-underwear-tent-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/79/valore-underwear-tent-1.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/79/valore-underwear-tent-2.jpg",
    //     },
    //   ],
    //   size: ["m", "l", "xl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "78",
    //   title: t('underwear-puppies'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/78/valore-underwear-puppies-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/78/valore-underwear-puppies-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/78/valore-underwear-puppies-1.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/78/valore-underwear-puppies-2.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/78/valore-underwear-puppies-3.jpg",
    //     },
    //   ],
    //   size: ["xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // added on April 17 2024
    {
      id: "77",
      title: t('t-shirt-dark-grey'),
      description: 't-shirt-man-cotton-100-percentage',
      leadingImage: "/images/shop/77/valore-t-shirt-dark-grey-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/77/valore-t-shirt-dark-grey-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/77/valore-t-shirt-dark-grey-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/77/valore-t-shirt-dark-grey-2.jpg",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-t-shirt"),
      availableQuantity: 10,
      priceRSD: 1790,
      priceEURO: 15.3,
    },
    {
      id: "76",
      title: t('underwear-orange'),
      description: 'underwear-man',
      leadingImage: "/images/shop/76/valore-underwear-orange-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/76/valore-underwear-orange-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/76/valore-underwear-orange-1.jpg",
        },
      ],
      size: ["m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    // {
    //   id: "75",
    //   title: t('underwear-green'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/75/valore-underwear-green-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/75/valore-underwear-green-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/75/valore-underwear-green-1.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/75/valore-underwear-green-2.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/75/valore-underwear-green-3.jpg",
    //     },
    //   ],
    //   size: ["m", "l", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    {
      id: "74",
      title: t('t-shirt-black'),
      description: 't-shirt-man-cotton-100-percentage',
      leadingImage: "/images/shop/74/valore-t-shirt-black-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/74/valore-t-shirt-black-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/74/valore-t-shirt-black-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/74/valore-t-shirt-black-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/74/valore-t-shirt-black-3.jpg",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-t-shirt"),
      availableQuantity: 10,
      priceRSD: 1790,
      priceEURO: 15.3,
    },
    {
      id: "73",
      title: t('t-shirt-moja-slavice'),
      description: 't-shirt-woman',
      leadingImage: "/images/shop/73/valore-t-shirt-moja-slavice-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/73/valore-t-shirt-moja-slavice-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/73/valore-t-shirt-moja-slavice-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/73/valore-t-shirt-moja-slavice-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/73/valore-t-shirt-moja-slavice-3.png",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-t-shirt"),
      availableQuantity: 10,
      priceRSD: 1790,
      priceEURO: 15.3,
    },
    // added on March 6 2024
    // {
    //   id: "72",
    //   title: t('underwear-soft-pink'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/72/valore-underwear-soft-pink-leading.jpeg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/72/valore-underwear-soft-pink-leading.jpeg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/72/valore-underwear-soft-pink-1.jpeg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/72/valore-underwear-soft-pink-2.jpg",
    //     },
    //   ],
    //   size: ["xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "71",
    //   title: t('underwear-maroon'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/71/valore-underwear-maroon-leading.jpeg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/71/valore-underwear-maroon-leading.jpeg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/71/valore-underwear-maroon-1.jpeg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/71/valore-underwear-maroon-2.jpg",
    //     },
    //   ],
    //   size: ["l", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "70",
    //   title: t('underwear-amarant'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/70/valore-underwear-amarant-leading.jpeg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/70/valore-underwear-amarant-leading.jpeg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/70/valore-underwear-amarant-1.jpeg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/70/valore-underwear-amarant-2.jpeg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/70/valore-underwear-amarant-3.jpg",
    //     },
    //   ],
    //   size: ["xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // added on February 20 2024
    // {
    //   id: "69",
    //   title: t('brazilian-soft-pink'),
    //   description: 'underwear-brazilian-woman',
    //   leadingImage: "/images/shop/69/valore-brazilian-soft-pink-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/69/valore-brazilian-soft-pink-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/69/valore-brazilian-soft-pink-1.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/69/valore-brazilian-soft-pink-2.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    {
      id: "68",
      title: t('brazilian-maroon'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/68/valore-brazilian-maroon-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/68/valore-brazilian-maroon-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/68/valore-brazilian-maroon-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/68/valore-brazilian-maroon-2.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "67",
      title: t('brazilian-amarant'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/67/valore-brazilian-amarant-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/67/valore-brazilian-amarant-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/67/valore-brazilian-amarant-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/67/valore-brazilian-amarant-2.jpg",
        },
      ],
      size: ["s", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "66",
      title: t('thongs-soft-pink'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/66/valore-thongs-soft-pink-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/66/valore-thongs-soft-pink-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/66/valore-thongs-soft-pink-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/66/valore-thongs-soft-pink-2.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "65",
      title: t('thongs-maroon'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/65/valore-thongs-maroon-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/65/valore-thongs-maroon-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/65/valore-thongs-maroon-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/65/valore-thongs-maroon-2.jpg",
        },
        {
          id: 3,
          src: "/images/shop/65/valore-thongs-maroon-3.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    {
      id: "64",
      title: t('thongs-amarant'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/64/valore-thongs-amarant-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/64/valore-thongs-amarant-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/64/valore-thongs-amarant-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/64/valore-thongs-amarant-2.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // added on December 10 2023
    // {
    //   id: "63",
    //   title: t('top-hen'),
    //   description: 'top-woman',
    //   leadingImage: "/images/shop/63/valore-top-hen-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/63/valore-top-hen-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/63/valore-top-hen-1.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/63/valore-top-hen-2.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/63/valore-top-hen-3.png",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-top"),
    //   availableQuantity: 10,
    //   priceRSD: 860,
    //   priceEURO: 7.4,
    // },
    // {
    //   id: "62",
    //   title: t('underwear-blue'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/62/valore-underwear-blue-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/62/valore-underwear-blue-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/62/valore-underwear-blue-1.jpg",
    //     },
    //   ],
    //   size: ["m", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    {
      id: "61",
      title: t('underwear-boats'),
      description: 'underwear-man',
      leadingImage: "/images/shop/61/valore-underwear-boats-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/61/valore-underwear-boats-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/61/valore-underwear-boats-1.jpg",
        },
      ],
      size: ["l", "xl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "60",
      title: t('underwear-triangles'),
      description: 'underwear-man',
      leadingImage: "/images/shop/60/valore-underwear-triangles-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/60/valore-underwear-triangles-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/60/valore-underwear-triangles-1.jpg",
        },
      ],
      size: ["m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "59",
      title: t('thongs-hen'),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/59/valore-thongs-hen-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/59/valore-thongs-hen-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/59/valore-thongs-hen-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/59/valore-thongs-hen-2.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // {
    //   id: "58",
    //   title: t('underwear-half-boxers-hen'),
    //   description: 'underwear-brazilian-woman',
    //   leadingImage: "/images/shop/58/valore-underwear-half-boxers-hen-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/58/valore-underwear-half-boxers-hen-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/58/valore-underwear-half-boxers-hen-1.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/58/valore-underwear-half-boxers-hen-2.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/58/valore-underwear-half-boxers-hen-3.jpg",
    //     },
    //   ],
    //   size: ["s", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    {
      id: "57",
      title: t('brazilian-panties-hen'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/57/valore-brazilian-panties-hen-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/57/valore-brazilian-panties-hen-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/57/valore-brazilian-panties-hen-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/57/valore-brazilian-panties-hen-2.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // added on October 12 2023
    // {
    //   id: "56",
    //   title: t('t-shirt-schnauzer'),
    //   description: 't-shirt-man',
    //   leadingImage: "/images/shop/56/valore-t-shirt-schnauzer-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/56/valore-t-shirt-schnauzer-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/56/valore-t-shirt-schnauzer-1.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/56/valore-t-shirt-schnauzer-2.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/56/valore-t-shirt-schnauzer-3.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-t-shirt"),
    //   availableQuantity: 0,
    //   priceRSD: 1590,
    //   priceEURO: 14,
    // },
    // {
    //   id: "55",
    //   title: t('underwear-rooster'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/55/valore-underwear-green-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/55/valore-underwear-green-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/55/valore-underwear-green-1.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/55/valore-underwear-green-2.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/55/valore-underwear-green-3.jpg",
    //     },
    //   ],
    //   size: ["xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    {
      id: "54",
      title: t('underwear-gray'),
      description: 'underwear-man',
      leadingImage: "/images/shop/54/valore-underwear-gray-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/54/valore-underwear-gray-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/54/valore-underwear-gray-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/54/valore-underwear-gray-2.jpg",
        },
      ],
      size: ["m", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "53",
      title: t('underwear-schnauzer'),
      description: 'underwear-man',
      leadingImage: "/images/shop/53/valore-underwear-schnauzer-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/53/valore-underwear-schnauzer-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/53/valore-underwear-schnauzer-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/53/valore-underwear-schnauzer-2.jpg",
        },
      ],
      size: ["xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    {
      id: "52",
      title: t('underwear-mole'),
      description: 'underwear-man',
      leadingImage: "/images/shop/52/valore-underwear-mole-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/52/valore-underwear-mole-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/52/valore-underwear-mole-1.jpg",
        },
        {
          id: 2,
          src: "/images/shop/52/valore-underwear-mole-2.jpg",
        },
      ],
      size: ["xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    // added on August 1 2023
    // {
    //   id: "51",
    //   title: t('thongs-dark-blue'),
    //   description: 'underwear-thong-woman',
    //   leadingImage: "/images/shop/51/valore-thongs-dark-blue-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/51/valore-thongs-dark-blue-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/51/valore-thongs-dark-blue-1.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    // {
    //   id: "50",
    //   title: t('thongs-kerosene'),
    //   description: 'underwear-thong-woman',
    //   leadingImage: "/images/shop/50/valore-thongs-kerosene-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/50/valore-thongs-kerosene-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/50/valore-thongs-kerosene-1.jpg",
    //     },
    //   ],
    //   size: ["l"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    // {
    //   id: "49",
    //   title: t('underwear-half-boxers-dark-blue'),
    //   description: 'underwear-brazilian-woman',
    //   leadingImage: "/images/shop/49/valore-underwear-half-boxers-dark-blue-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/49/valore-underwear-half-boxers-dark-blue-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/49/valore-underwear-half-boxers-dark-blue-1.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    {
      id: "48",
      title: t('brazilian-panties-dark-blue'),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/48/valore-brazilian-panties-dark-blue-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/48/valore-brazilian-panties-dark-blue-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/48/valore-brazilian-panties-dark-blue-1.jpg",
        },
      ],
      size: ["s", "m", "l"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // {
    //   id: "47",
    //   title: t('brazilian-panties-kerosene'),
    //   description: 'underwear-brazilian-woman',
    //   leadingImage: "/images/shop/47/valore-brazilian-panties-kerosene-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/47/valore-brazilian-panties-kerosene-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/47/valore-brazilian-panties-kerosene-1.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    {
      id: "46",
      title: t('t-shirt-light-blue'),
      description: 't-shirt-woman',
      leadingImage: "/images/shop/46/valore-t-shirt-light-blue-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/46/valore-t-shirt-light-blue-leading.jpg",
        },
        {
          id: 1,
          src: "/images/shop/46/valore-t-shirt-light-blue-1.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-t-shirt"),
      availableQuantity: 10,
      priceRSD: 1590,
      priceEURO: 14,
    },
    // {
    //   id: "45",
    //   title: t('underwear-turtles'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/45/valore-underwear-turtles-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/45/valore-underwear-turtles-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/45/valore-underwear-turtles-1.jpg",
    //     },
    //   ],
    //   size: ["m", "l", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "44",
    //   title: t('underwear-hedgehogs'),
    //   mark: t('mark-sold'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/44/valore-underwear-hedgehogs-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/44/valore-underwear-hedgehogs-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/44/valore-underwear-hedgehogs-1.jpg",
    //     },
    //   ],
    //   size: ["m", "l", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "43",
    //   title: t('underwear-boars'),
    //   mark: t('mark-sold'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/43/valore-underwear-pigs-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/43/valore-underwear-pigs-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/43/valore-underwear-pigs-1.jpg",
    //     },
    //   ],
    //   size: ["m", "l", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "42",
    //   title: t('t-shirt-brown'),
    //   description: 't-shirt-man',
    //   leadingImage: "/images/shop/42/valore-t-shirt-brown-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/42/valore-t-shirt-brown-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/42/valore-t-shirt-brown-1.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-t-shirt"),
    //   availableQuantity: 10,
    //   priceRSD: 1590,
    //   priceEURO: 14,
    // },
    // {
    //   id: "41",
    //   title: t('t-shirt-chamois'),
    //   description: 't-shirt-man',
    //   leadingImage: "/images/shop/41/valore-t-shirt-chamois-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/41/valore-t-shirt-chamois-leading.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/41/valore-t-shirt-chamois-1.jpg",
    //     },
    //   ],
    //   size: ["m"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-t-shirt"),
    //   availableQuantity: 10,
    //   priceRSD: 1590,
    //   priceEURO: 14,
    // },
    // new added
    {
      id: "40",
      title: t("underwear-yellow"),
      description: 'underwear-man',
      leadingImage: "/images/shop/40/valore-underwear-yellow-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/40/valore-underwear-yellow-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/40/valore-underwear-yellow-2.jpg",
        },
      ],
      size: ["m", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 890,
      priceEURO: 7.6,
    },
    // {
    //   id: "39",
    //   title: t("thongs-purple"),
    //   description: 'underwear-thong-woman',
    //   leadingImage: "/images/shop/39/valore-thongs-purple-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/39/valore-thongs-purple-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/39/valore-thongs-purple-2.jpg",
    //     },
    //   ],
    //   size: ["l"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    // {
    //   id: "38",
    //   title: t("thongs-orange"),
    //   description: 'underwear-thong-woman',
    //   leadingImage: "/images/shop/38/valore-thongs-orange-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/38/valore-thongs-orange-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/38/valore-thongs-orange-2.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    // {
    //   id: "37",
    //   title: t("brazilian-purple"),
    //   description: 'underwear-brazilian-woman',
    //   leadingImage: "/images/shop/37/valore-brazilian-panties-purple-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/37/valore-brazilian-panties-purple-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/37/valore-brazilian-panties-purple-2.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    {
      id: "36",
      title: t("brazilian-orange"),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/36/valore-brazilian-panties-orange-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/36/valore-brazilian-panties-orange-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/36/valore-brazilian-panties-orange-2.jpg",
        },
      ],
      size: ["s"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // underwear
    // {
    //   id: "1",
    //   title: t("underwear-snails"),
    //   mark: t('mark-sold'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/1/valore-underwear-snails-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/1/valore-underwear-snails-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/1/valore-underwear-snails-2.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/1/valore-underwear-snails-3.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/1/valore-underwear-snails-4.jpg",
    //     },
    //   ],
    //   size: ["l", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "2",
    //   title: t("underwear-horses"),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/2/valore-underwear-horses-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/2/valore-underwear-horses-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/2/valore-underwear-horses-2.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/2/valore-underwear-horses-3.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/2/valore-underwear-horses-4.jpg",
    //     },
    //   ],
    //   size: ["m", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "3",
    //   title: t("underwear-birds"),
    //   mark: t('mark-sold'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/3/valore-underwear-birds-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/3/valore-underwear-birds-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/3/valore-underwear-birds-2.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/3/valore-underwear-birds-3.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/3/valore-underwear-birds-4.jpg",
    //     },
    //   ],
    //   size: ["m", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "4",
    //   title: t("underwear-red-frogs"),
    //   mark: t('mark-sold'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/4/valore-underwear-frogs-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/4/valore-underwear-frogs-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/4/valore-underwear-frogs-2.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/4/valore-underwear-frogs-3.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/4/valore-underwear-frogs-4.jpg",
    //     },
    //   ],
    //   size: ["m", "l", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "5",
    //   title: t("underwear-mongooses"),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/5/valore-underwear-mongooses-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/5/valore-underwear-mongooses-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/5/valore-underwear-mongooses-2.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/5/valore-underwear-mongooses-3.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/5/valore-underwear-mongooses-4.jpg",
    //     },
    //   ],
    //   size: ["m"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "6",
    //   title: t("underwear-giraffes"),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/6/valore-underwear-giraffes-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/6/valore-underwear-giraffes-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/6/valore-underwear-giraffes-2.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/6/valore-underwear-giraffes-3.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/6/valore-underwear-giraffes-4.jpg",
    //     },
    //   ],
    //   size: ["l", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // {
    //   id: "7",
    //   title: t("underwear-mosquitoes"),
    //   mark: t('mark-sold'),
    //   description: 'underwear-man',
    //   leadingImage: "/images/shop/7/valore-underwear-mosquitoes-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/7/valore-underwear-mosquitoes-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/7/valore-underwear-mosquitoes-2.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/7/valore-underwear-mosquitoes-3.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/7/valore-underwear-mosquitoes-4.jpg",
    //     },
    //   ],
    //   size: ["l", "xl", "xxl"],
    //   collection: t("shop-product-collection-man"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 7.6,
    // },
    // thongs
    {
      id: "8",
      title: t("thongs-orange-light"),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/8/valore-thongs-orange-light-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/8/valore-thongs-orange-light-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/8/valore-thongs-orange-light-2.jpg",
        },
      ],
      size: ["m"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // {
    //   id: "9",
    //   title: t("thongs-dark-green"),
    //   description: 'underwear-thong-woman',
    //   leadingImage: "/images/shop/9/valore-thongs-dark-green-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/9/valore-thongs-dark-green-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/9/valore-thongs-dark-green-2.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    // {
    //   id: "10",
    //   title: t("thongs-birds"),
    //   description: 'underwear-thong-woman',
    //   leadingImage: "/images/shop/10/valore-thongs-birds-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/10/valore-thongs-birds-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/10/valore-thongs-birds-2.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 0,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    // {
    //   id: "11",
    //   title: t("thongs-green"),
    //   description: 'underwear-thong-woman',
    //   leadingImage: "/images/shop/11/valore-thongs-green-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/11/valore-thongs-green-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/11/valore-thongs-green-2.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/11/valore-thongs-green-3.jpg",
    //     },
    //     {
    //       id: 3,
    //       src: "/images/shop/11/valore-thongs-green-4.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    {
      id: "12",
      title: t("thongs-pink"),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/12/valore-thongs-pink-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/12/valore-thongs-pink-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/12/valore-thongs-pink-2.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // {
    //   id: "13",
    //   title: t("thongs-brown"),
    //   description: 'underwear-thong-woman',
    //   leadingImage: "/images/shop/13/valore-thongs-brown-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/13/valore-thongs-brown-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/13/valore-thongs-brown-2.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    {
      id: "14",
      title: t("thongs-yellow"),
      description: 'underwear-thong-woman',
      leadingImage: "/images/shop/14/valore-thongs-yellow-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/14/valore-thongs-yellow-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/14/valore-thongs-yellow-2.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // brazilian panties
    {
      id: "15",
      title: t("brazilian-brown"),
      description: 'underwear-brazilian-woman',
      leadingImage: "/images/shop/15/valore-brazilian-panties-brown-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/15/valore-brazilian-panties-brown-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/15/valore-brazilian-panties-brown-2.jpg",
        },
      ],
      size: ["l"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-underwear"),
      availableQuantity: 10,
      priceRSD: 760,
      priceEURO: 6.5,
    },
    // {
    //   id: "16",
    //   title: t("brazilian-light-green"),
    //   description: 'underwear-brazilian-woman',
    //   leadingImage: "/images/shop/16/valore-brazilian-panties-light-green-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/16/valore-brazilian-panties-light-green-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/16/valore-brazilian-panties-light-green-2.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    // {
    //   id: "17",
    //   title: t("brazilian-dark-green"),
    //   description: 'underwear-brazilian-woman',
    //   leadingImage: "/images/shop/17/valore-brazilian-panties-dark-green-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/17/valore-brazilian-panties-dark-green-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/17/valore-brazilian-panties-dark-green-2.jpg",
    //     },
    //   ],
    //   size: ["m"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 10,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    // {
    //   id: "18",
    //   title: t("brazilian-birds"),
    //   description: 'underwear-brazilian-woman',
    //   leadingImage: "/images/shop/18/valore-brazilian-panties-birds-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/18/valore-brazilian-panties-birds-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/18/valore-brazilian-panties-birds-2.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 0,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    // {
    //   id: "19",
    //   title: t("brazilian-yellow"),
    //   mark: t('mark-sold'),
    //   description: 'underwear-brazilian-woman',
    //   leadingImage: "/images/shop/19/valore-brazilian-panties-yellow-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/19/valore-brazilian-panties-yellow-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/19/valore-brazilian-panties-yellow-2.jpg",
    //     },
    //   ],
    //   size: ["l", "xl", "xxl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-underwear"),
    //   availableQuantity: 0,
    //   priceRSD: 760,
    //   priceEURO: 6.5,
    // },
    // t-shirt
    {
      id: "20",
      title: t("t-shirt-unisex-dog"),
      description: 't-shirt-child',
      leadingImage: "/images/shop/20/valore-t-shirt-unisex-dog-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/20/valore-t-shirt-unisex-dog-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/20/valore-t-shirt-unisex-dog-2.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-children-t-shirt-sizes.jpg",
        },
      ],
      size: ["1", "2", "4", "6"],
      collection: t("shop-product-collection-child"),
      type: t("shop-product-type-t-shirt"),
      availableQuantity: 10,
      priceRSD: 1190,
      priceEURO: 10.2,
    },
    {
      id: "21",
      title: t("t-shirt-unisex-giraffe"),
      description: 't-shirt-child',
      leadingImage: "/images/shop/21/valore-t-shirt-unisex-giraffe-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/21/valore-t-shirt-unisex-giraffe-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/21/valore-t-shirt-unisex-giraffe-2.jpg",
        },
        {
          id: 2,
          src: "/images/shop/21/valore-t-shirt-unisex-giraffe-3.jpg",
        },
        {
          id: 3,
          src: "/images/shop/21/valore-t-shirt-unisex-giraffe-4.jpg",
        },
      ],
      size: ["1", "2", "4", "6"],
      collection: t("shop-product-collection-child"),
      type: t("shop-product-type-t-shirt"),
      availableQuantity: 10,
      priceRSD: 1190,
      priceEURO: 10.2,
    },
    {
      id: "22",
      title: t("t-shirt-unisex-bear"),
      description: 't-shirt-child',
      leadingImage: "/images/shop/22/valore-t-shirt-unisex-bear-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/22/valore-t-shirt-unisex-bear-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/22/valore-t-shirt-unisex-bear-2.jpg",
        },
        {
          id: 2,
          src: "/images/shop/22/valore-t-shirt-unisex-bear-3.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-children-t-shirt-sizes.jpg",
        },
      ],
      size: ["1", "2", "4", "6"],
      collection: t("shop-product-collection-child"),
      type: t("shop-product-type-t-shirt"),
      availableQuantity: 10,
      priceRSD: 1190,
      priceEURO: 10.2,
    },
    {
      id: "23",
      title: t("t-shirt-unisex-bird"),
      description: 't-shirt-child',
      leadingImage: "/images/shop/23/valore-t-shirt-unisex-bird-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/23/valore-t-shirt-unisex-bird-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/23/valore-t-shirt-unisex-bird-2.jpg",
        },
        {
          id: 2,
          src: "/images/shop/23/valore-t-shirt-unisex-bird-3.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-children-t-shirt-sizes.jpg",
        },
      ],
      size: ["1", "2", "4", "6"],
      collection: t("shop-product-collection-child"),
      type: t("shop-product-type-t-shirt"),
      availableQuantity: 10,
      priceRSD: 1190,
      priceEURO: 10.2,
    },
    // sweatsuit
    {
      id: "24",
      title: t("sweatsuit-unisex-light-green"),
      description: 'sweatsuit-child',
      leadingImage: "/images/shop/24/valore-sweatsuit-unisex-light-green-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/24/valore-sweatsuit-unisex-light-green-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/24/valore-sweatsuit-unisex-light-green-2.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-children-sweatsuit-sizes.jpg",
        },
      ],
      size: ["6"],
      collection: t("shop-product-collection-child"),
      type: t("shop-product-type-sweatsuit"),
      availableQuantity: 10,
      priceRSD: 2900,
      priceEURO: 24.5,
    },
    {
      id: "25",
      title: t("sweatsuit-unisex-dino"),
      description: 'sweatsuit-child',
      leadingImage: "/images/shop/25/valore-sweatsuit-unisex-dino-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/25/valore-sweatsuit-unisex-dino-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/25/valore-sweatsuit-unisex-dino-2.jpg",
        },
        {
          id: 2,
          src: "/images/shop/25/valore-sweatsuit-unisex-dino-3.jpg",
        },
        {
          id: 3,
          src: "/images/shop/sizes/valore-children-sweatsuit-sizes.jpg",
        },
      ],
      size: ["1", "2", "4", "6"],
      collection: t("shop-product-collection-child"),
      type: t("shop-product-type-sweatsuit"),
      availableQuantity: 10,
      priceRSD: 2900,
      priceEURO: 24.5,
    },
    {
      id: "26",
      title: t("sweatsuit-unisex-orange"),
      description: 'sweatsuit-child',
      leadingImage: "/images/shop/26/valore-sweatsuit-unisex-orange-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/26/valore-sweatsuit-unisex-orange-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/26/valore-sweatsuit-unisex-orange-2.jpg",
        },
        {
          id: 2,
          src: "/images/shop/sizes/valore-children-sweatsuit-sizes.jpg",
        },
      ],
      size: ["1", "2", "4", "6"],
      collection: t("shop-product-collection-child"),
      type: t("shop-product-type-sweatsuit"),
      availableQuantity: 10,
      priceRSD: 2900,
      priceEURO: 24.5,
    },
    // sweatshirt
    {
      id: "27",
      title: t("sweatshirt-green-oversize"),
      description: 'sweatshirt-woman',
      leadingImage: "/images/shop/27/valore-sweatshirt-green-oversize-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/27/valore-sweatshirt-green-oversize-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/27/valore-sweatshirt-green-oversize-2.jpg",
        },
        {
          id: 2,
          src: "/images/shop/27/valore-sweatshirt-green-oversize-3.jpg",
        },
      ],
      size: ["s", "m", "l"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-sweatshirt"),
      availableQuantity: 10,
      priceRSD: 2900,
      priceEURO: 25,
    },
    {
      id: "28",
      title: t("sweatshirt-black-half-slim"),
      description: 'sweatshirt-man-half-slim',
      leadingImage: "/images/shop/28/valore-sweatshirt-black-half-slim-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/28/valore-sweatshirt-black-half-slim-1.jpg",
        },
      ],
      size: ["m", "l"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-sweatshirt"),
      availableQuantity: 10,
      priceRSD: 2900,
      priceEURO: 25,
    },
    {
      id: "29",
      title: t("sweatshirt-sloth-half-slim"),
      description: 'sweatshirt-man-half-slim',
      leadingImage: "/images/shop/29/valore-sweatshirt-sloth-half-slim-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/29/valore-sweatshirt-sloth-half-slim-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/29/valore-sweatshirt-sloth-half-slim-2.jpg",
        },
      ],
      size: ["m", "l"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-sweatshirt"),
      availableQuantity: 10,
      priceRSD: 2900,
      priceEURO: 25,
    },
    {
      id: "30",
      title: t("sweatshirt-orange"),
      description: 'sweatshirt-man',
      leadingImage: "/images/shop/30/valore-sweatshirt-orange-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/30/valore-sweatshirt-orange-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/30/valore-sweatshirt-orange-2.jpg",
        },
      ],
      size: ["m", "l", "xl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-sweatshirt"),
      availableQuantity: 10,
      priceRSD: 2900,
      priceEURO: 25,
    },
    {
      id: "31",
      title: t("sweatshirt-black"),
      description: 'sweatshirt-man',
      leadingImage: "/images/shop/31/valore-sweatshirt-black-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/31/valore-sweatshirt-black-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/31/valore-sweatshirt-black-2.jpg",
        },
        {
          id: 2,
          src: "/images/shop/31/valore-sweatshirt-black-3.jpg",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-sweatshirt"),
      availableQuantity: 10,
      priceRSD: 2900,
      priceEURO: 25,
    },
    // {
    //   id: "32",
    //   title: t("t-shirt-slim-fit-black"),
    //   mark: t('mark-sold'),
    //   description: 't-shirt-woman',
    //   leadingImage: "/images/shop/32/valore-t-shirt-slim-fit-black-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/32/valore-t-shirt-slim-fit-black-1.jpg",
    //     },
    //   ],
    //   size: ["l", "xl", "xxl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-t-shirt"),
    //   availableQuantity: 10,
    //   priceRSD: 890,
    //   priceEURO: 14,
    // },
    // {
    //   id: "33",
    //   title: t("sweatsuit-double-face-orange"),
    //   description: 'sweatsuit-woman-double-face',
    //   leadingImage: "/images/shop/33/valore-sweatsuit-double-face-orange-leading.jpg",
    //   images: [
    //     {
    //       id: 0,
    //       src: "/images/shop/33/valore-sweatsuit-double-face-orange-1.jpg",
    //     },
    //     {
    //       id: 1,
    //       src: "/images/shop/33/valore-sweatsuit-double-face-orange-2.jpg",
    //     },
    //     {
    //       id: 2,
    //       src: "/images/shop/33/valore-sweatsuit-double-face-orange-3.jpg",
    //     },
    //   ],
    //   size: ["s", "m", "l", "xl"],
    //   collection: t("shop-product-collection-woman"),
    //   type: t("shop-product-type-sweatsuit"),
    //   availableQuantity: 10,
    //   priceRSD: 9500,
    //   priceEURO: 81.2,
    // },
    {
      id: "34",
      title: t("sweatsuit-double-face-light"),
      description: 'sweatsuit-woman-double-face',
      leadingImage: "/images/shop/34/valore-sweatsuit-double-face-light-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/34/valore-sweatsuit-double-face-light-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/34/valore-sweatsuit-double-face-light-2.jpg",
        },
      ],
      size: ["s", "m", "l", "xl"],
      collection: t("shop-product-collection-woman"),
      type: t("shop-product-type-sweatsuit"),
      availableQuantity: 10,
      priceRSD: 9500,
      priceEURO: 81.2,
    },
    {
      id: "35",
      title: t("sweatsuit-double-face-black"),
      description: 'sweatsuit-man-double-face',
      leadingImage: "/images/shop/35/valore-sweatsuit-double-face-black-leading.jpg",
      images: [
        {
          id: 0,
          src: "/images/shop/35/valore-sweatsuit-double-face-black-1.jpg",
        },
        {
          id: 1,
          src: "/images/shop/35/valore-sweatsuit-double-face-black-2.jpg",
        },
        {
          id: 2,
          src: "/images/shop/35/valore-sweatsuit-double-face-black-3.jpg",
        },
      ],
      size: ["s", "m", "l", "xl", "xxl"],
      collection: t("shop-product-collection-man"),
      type: t("shop-product-type-sweatsuit"),
      availableQuantity: 10,
      priceRSD: 9500,
      priceEURO: 81.2,
    },
  ];

  return data;
}
