import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SlickSliderHomeItem } from '../';
import { shopData } from '../../data/';
import { useTranslation } from 'react-i18next';

const SliderStyled = styled(Slider)`
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 3rem;

  .slick-dots {
    bottom: -3rem;

    li {
      button::before {
        color: ${props => props.theme.colors.lightPrimary};
      }

      &.slick-active {
        button::before {
          color: ${props => props.theme.colors.primaryColor};
        }
      }
    }
  }

  .slick-arrow {
    z-index: 10;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
`;

const SlickSliderHome = () => {
  const { t } = useTranslation();
  const [sliderData, setSliderData] = useState();

  function findShopDataByIds(shopData, sliderItemsIds) {
    return shopData.filter(shopDataItem => sliderItemsIds.includes(shopDataItem.id));
  }

  useEffect(() => {
    const shopDataArray = shopData(t);

    const sliderItemsIds = ["118", "117", "101", "99", "96", "93", "84", "82", "77", "53", "20", "25"];

    const filteredData = findShopDataByIds(shopDataArray, sliderItemsIds);
    setSliderData(filteredData);
  }, [t])

  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 7000,
    slidesToScroll: 3,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <SliderStyled {...sliderSettings}>
      {sliderData && sliderData.map((item, key) => <SlickSliderHomeItem key={`home-slider-item-${key}`} title={item.title} leadingImage={item.leadingImage} linkPath={item.id} mark={item.mark} />)}
    </SliderStyled>
  );
}

export default SlickSliderHome;
