import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ButtonCustom, ProductItemShopCard, ContainerCustom, TextCustom, Modal } from '../components';
import { Row, Col } from 'react-grid-system';
import { shopData } from '../data/';
import { getWindowSize, hexToRgb } from '../functions';
import { FilterMultipleIcon, ShirtIcon } from '../icons';
import { useSelector } from 'react-redux';

const InputTextStyled = styled.input`
  width: calc(100% - 20px);
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 1rem;

  &:focus, &:hover {
    outline: none;
    transition: .3s;
    border: 1px solid ${props => props.theme.colors.primaryColor};
  }
`;
const FiltersStyled = styled(Col)`
  flex-direction: column;
  flex-wrap: wrap;
  position: sticky !important;
  top: 58px;
  width: calc(25% - 15px);
  height: 100%;

  @media (max-width: 1200px) {
    width: calc(100% - 30px);
  }
`;

const ProductsStyled = styled(Col)`
  @media (max-width: 1200px) {
    & > h2 {
      position: sticky;
      top: 49px;
      z-index: 2;
      background-color: ${props => props.theme.colors.lightPrimary};
      padding: 15px 0;
    }
  }

  &.active > h2 {
    width: calc(100% + 30px);
    margin-left: -15px;
    padding-left: 15px;
    -webkit-box-shadow: 0px 10px 20px -20px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .75)`};
    -moz-box-shadow: 0px 10px 20px -20px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .75)`};
    box-shadow: 0px 10px 20px -20px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .75)`};

    @media (max-width: 600px) {
      width: calc(100% + 15px);
    }
  }
`;

const SidebarFilterItemStyled = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;

  button {
    margin: 0 3px 3px 0;

    &:hover {
      transition: .3s;
      background-color: ${props => props.theme.colors.primaryColor};
    }
  }

  button.active {
    background-color: ${props => props.theme.colors.primaryColor};
  }
`;

const SidebarFiltersFormStyled = styled.form`
  padding-right: 20px;

  & > button:hover {
    transition: .3s;
    background-color: ${props => props.theme.colors.primaryColor};
    color: ${props => props.theme.colors.darkPrimary};
  }
`;

const FiltersModalTrigger = styled.div`
  position: absolute;
  top: 7px;
  right: 15px;
  z-index: 3;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .25)`};
  -moz-box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .25)`};
  box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .25)`};

  &:hover {
    transition: all.3s;
    transform: scale(1.1);
    -webkit-box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
    -moz-box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
    box-shadow: 0px 0px 10px 0px ${props => `rgba(${hexToRgb(props.theme.colors.darkPrimary)}, .5)`};
  }
`;

const FiltersModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  background-color: ${props => props.theme.colors.lightPrimary};
  border-radius: 6px;
  overflow-y: auto;

  & > aside {
    padding: 15px;

    & > form {
      padding-right: 0;
    }
  }

  @media (max-width: 550px) {
    width: auto;
  }
`;

const Shop = () => {
  const { collectionType } = useParams();
  const theme = useSelector(state => state.settings.theme);
  const { darkPrimary, lightPrimary, primaryColor } = theme.colors;
  const { t } = useTranslation();
  const [shopItems, setShopItems] = useState([]);
  const [filteredShopItems, setFilteredShopItems] = useState(shopItems);
  const [activeCriteriaType, setActiveCriteriaType] = useState('');
  const [activeCriteriaValue, setActiveCriteriaValue] = useState('');
  const [offset, setOffset] = useState(0);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const filtersData = {
    sizes: ['s', 'm', 'l', 'xl', 'xxl', '3xl'],
    types: [t("shop-filters-type-underwear"), t("shop-filters-type-t-shirt"), t("shop-filters-type-sweatshirt"), t("shop-filters-type-sweatsuit"), t("shop-filters-type-top"), t("shop-filters-type-pajamas")],
    collections: [t("shop-filters-collection-man"), t("shop-filters-collection-woman"), t("shop-filters-collection-child")]
  };

  useEffect(() => {
    const shopDataItems = shopData(t);
    setShopItems(shopDataItems);
    setFilteredShopItems(shopDataItems);

    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };
  
    const onScroll = () => setOffset(window.pageYOffset);

    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', handleWindowResize);

		window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('resize', handleWindowResize);

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', handleWindowResize);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };
  }, [collectionType, t]);

  const filterByName = (value) => {
    setActiveCriteriaType('');
    setActiveCriteriaValue('');

    if (value === '') {
      resetFilters();
    }
    let filteredItems = shopItems.filter(item => item.title.toLowerCase().includes(value.toLowerCase()));
    setFilteredShopItems(filteredItems);
  }

  const filterByCriteria = (criteriaType, criteriaValue) => {
    if (criteriaValue !== '') {
      let filteredItems = [];

      // specific rule for sizes
      if (criteriaType === 'size') {
        filteredItems = shopItems.filter(item => item[criteriaType].includes(criteriaValue.toLowerCase()));
      } else {
        filteredItems = shopItems.filter(item => item[criteriaType] === criteriaValue.toLowerCase());
      }
      
      setFilteredShopItems(filteredItems);
      setActiveCriteriaType(criteriaType);
      setActiveCriteriaValue(criteriaValue);
      return;
    }
  }

  const resetFilters = () => {
    setFilteredShopItems(shopItems);
    setActiveCriteriaType('');
    setActiveCriteriaValue('');
  }

  const filtersDataContent = <>
    <FiltersStyled xs={3} as='aside'>
      <TextCustom as='h2' size='1.2rem'><FilterMultipleIcon width={18} height={18} /> 
        {t("shop-filters")} 
        {activeCriteriaType !== '' ? ` (${activeCriteriaType}: ` : null} 
        {activeCriteriaValue !== '' ? `${activeCriteriaValue})` : null} 
      </TextCustom>

      <SidebarFiltersFormStyled onSubmit={(e) => e.preventDefault()}>
        {/* Filter by title */}
        <SidebarFilterItemStyled>
          <TextCustom as='h3' size='1rem' margin='0 0 10px 0'>{t("shop-filters-title")}</TextCustom>
          <InputTextStyled onChange={(e) => filterByName(e.target.value)} type='text' placeholder={t("shop-filters-title-placeholder")} />
        </SidebarFilterItemStyled>
        {/* Filter by size */}
        <SidebarFilterItemStyled>
          <TextCustom as='h3' size='1rem' margin='0 0 10px 0'>{t("shop-filters-size")}</TextCustom>

          {filtersData.sizes.map((val, key) => {
            return <ButtonCustom 
              key={`filter-size-${key}`}
              as='button'
              size='10px'
              padding='5px 10px'
              className={activeCriteriaType === 'size' && activeCriteriaValue === val ? 'active' : ''}
              onClick={() => filterByCriteria('size', val)}
            >
              {val.toUpperCase()}
            </ButtonCustom>
          })}
        </SidebarFilterItemStyled>
        {/* Filter by type */}
        <SidebarFilterItemStyled>
          <TextCustom as='h3' size='1rem' margin='0 0 10px 0'>{t("shop-filters-type")}</TextCustom>

          {filtersData.types.map((val, key) => {
            return <ButtonCustom 
              key={`filter-type-${key}`}
              as='button'
              size='10px'
              padding='5px 10px'
              className={activeCriteriaType === 'type' && activeCriteriaValue === val ? 'active' : ''}
              onClick={() => filterByCriteria('type', val)}
            >
              {val}
            </ButtonCustom>
          })}
        </SidebarFilterItemStyled>
        {/* Filter by collection */}
        <SidebarFilterItemStyled>
          <TextCustom as='h3' size='1rem' margin='0 0 10px 0'>{t("shop-filters-collection")}</TextCustom>

          {filtersData.collections.map((val, key) => {
            return <ButtonCustom 
              key={`filter-collection-${key}`}
              as='button'
              size='10px'
              padding='5px 10px'
              className={activeCriteriaType === 'collection' && activeCriteriaValue === val ? 'active' : ''}
              onClick={() => filterByCriteria('collection', val)}
            >
              {val}
            </ButtonCustom>
          })}

        </SidebarFilterItemStyled>

        <ButtonCustom
          as='button'
          size='15px'
          padding='5px 25px'
          onClick={() => resetFilters()}
        >
          {t("shop-filters-button")}
        </ButtonCustom>

      </SidebarFiltersFormStyled>
    </FiltersStyled>
  </>;

  return (
    <>
      {/* Shop - welcome container */}
      <ContainerCustom 
        fluid={true}
        align='center'
        justify='center'
        bgImage='/images/shop-cover.jpg' 
        bgImageSize='cover' 
        bgImagePosition='center center' 
        minHeight='300px'
        bgRgbaColor={hexToRgb(darkPrimary)} 
        bgRgbaOpacity={.9}
      >
        <Col className='text-light text-center'>
          <TextCustom as='h1' size='2.5rem' margin='0 0 10px 0'>Valore Intimo <TextCustom as='span' color={primaryColor}>{t("shop-welcome-title-span")}</TextCustom></TextCustom>
          <TextCustom as='h3' size='1.05rem'>{t("shop-welcome-description")}</TextCustom>
        </Col>
      </ContainerCustom>

      <ContainerCustom padding='50px 0px' justify='between' bgColor={lightPrimary}>
        {windowSize.innerWidth >= 1200 ? filtersDataContent : null}

        <ProductsStyled xs={12} xl={9} className={offset > 300 ? 'active' : ''}>
          <TextCustom as='h2' size='1.2rem'>
            <ShirtIcon width={18} height={18} /> {t("shop-products")}
            {windowSize.innerWidth < 1200 ? <Modal
              trigger={<FiltersModalTrigger>
                  <FilterMultipleIcon />
                </FiltersModalTrigger>
              }
            >
              <FiltersModalWrapper>
                {filtersDataContent}
              </FiltersModalWrapper>
            </Modal> : null}
          </TextCustom>

          <Row>
            {filteredShopItems ? filteredShopItems.length !== 0 ? filteredShopItems.map((item) => {
                return (
                  <Col xs={12} sm={6} md={4} lg={3} as='article' key={item.id}>
                    <ProductItemShopCard
                      linkPath={`/shop/${item.id}`}
                      title={item.title}
                      leadingImage={item.leadingImage}
                      mark={item.mark}
                    />
                  </Col>
                )
              }) : (
                <Col>
                  <p>{t("shop-no-products")}</p>
                </Col>
              ) : (
              <Col>
                <p>{t("loading")}</p>
              </Col>
            )}
          </Row>
        </ProductsStyled>
      </ContainerCustom>
    </>
  );
};

export default Shop;
